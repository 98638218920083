
const columnsList =  [
  {
    name: 'nome_cliente',
    field: 'nome_cliente',
    label: 'Cliente',
    style: 'width: 100px;',
    align: 'left',
    sortable: true,
  },
  {
    name: 'codigo_assessor',
    label: 'Código do assessor',
    field: (row) => row.codigo_assessor,
    align: 'left',
    sortable: true,
  },

  {
    name: 'Assessor',
    label: 'Assessor',
    field: (row) => row.nome_assessor,
    align: 'left',
    sortable: true,
  },

  {
    name: 'tag',
    label: 'Tag',
    // field: (row) => row.tag,
    align: 'left',
    sortable: false,
  },
 
]
const columnsSide =  [
  {
    name: 'card',
    field: 'card',
    label: '',
  
    align: 'left',
    sortable: false ,
  },
 
 
]

export {  columnsList, columnsSide }

