<template>
  <q-chip
  v-bind="attrs"
    :size="null"
    :data-size="attrs.size"
    :style="styleChip()"
    :ripple="false"
    class="OBadge"
    :class="{
      '!rounded-full h-20 cursor-pointer': attrs.small,
    }"
  >
    <q-badge
      v-if="attrs.badge"
      rounded
      class="shrink-0 w-8 h-8"
      
      :style="styleBadge"
    ></q-badge>

    <q-tooltip v-if="attrs.small">
      <slot name="content"></slot>
    </q-tooltip>

    <slot v-else name="content"></slot>
  </q-chip>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import {  computed, useAttrs, useSlots } from 'vue'
import { useQuasar } from 'quasar'

const slots = useSlots()
const attrs = useAttrs()
const $q = useQuasar()

const styleChip = () => ({
  color: `${
    attrs.color
      ? `${
          $q.dark.isActive
            ? 'rgba(var(--white), 0.9)'
            : `rgba(${attrs.color}, 1)`
        }`
      : `rgba(${attrs.bg}, 1)`
  }`,
  background: $q.dark.isActive
    ? `rgba(${attrs.badgecolor}, 0.2)`
    : `rgba(${attrs.badgecolor}, 0.09 )`,
})

const styleBadge = computed(() => ({
  background: `rgba(${ attrs.color}, 1)`,
}))

</script>

<style lang="sass" scoped>
.OBadge
  :deep(.q-icon)
    font-size: 20px
    margin-right: 0
    color: currentColor
</style>
