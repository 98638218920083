<template>
  <div class="o-chat-message mt-16">
    <q-chat-message
      :avatar="data.usuario?.foto"
      :text="[data.mensagem]"
      :sent="sender"
      bg-color="primary-pure/5"
      class="max-w-[600px]"
      :class="{
        'ml-auto': sender,
        'mr-auto': !sender,
      }"
    >
      <template v-if="data.obj_anexo" #default>
        <FileAnexo :file="data.obj_anexo" />
      </template>
    </q-chat-message>

    <div
      class="flex items-center gap-6 text-paragraph-3 mt-4"
      :class="{
        'justify-start mr-[2.75rem] md:mr-[2.25rem] flex-row-reverse': sender,
        'ml-[2.75rem] md:ml-[2.25rem]': !sender,
      }"
    >
      <span class="capitalize">{{ data.usuario?.nome || 'Sem nome' }}</span>
      <span class="text-neutral-100/40 dark:text-white/10">•</span>

      <span class="text-neutral-60 dark:text-white/40"
        >{{ date.formatDate(data.data_criacao, 'DD/MM/YY') }} • 
        {{ data.data_amigavel }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { date } from 'quasar'
import { inject } from 'vue'
import FileAnexo from 'components/File/FileAnexo.vue'

const props = defineProps({
  data: Object,
})

const {
  data: { id_user },
} = inject('context')

const sender = props.data.usuario_criacao === id_user
</script>

<style lang="sass">
@import "src/css/responsivo.sass"

.o-chat-message
  @include d(md)
    .q-message-avatar
      width: 1.5rem
      height: 1.5rem
      min-width: auto

    .q-message-text
      font-size: .75rem
      font-weight: 500 !important

    span, .tag-base-projeto
      font-size: .625rem
</style>
