import axios from 'axios'

const csrf = window.context.csrf

const API_HEADER = {
  post: {
    'X-CSRFTOKEN': csrf,
  },
  'X-CSRFToken': csrf,
}

const api = axios.create({
  baseURL: '/',
  withCredentials: true,
  headers: API_HEADER,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
})

const URLS = {
  users: '/api/users/',
  parceiro: 'parceiro/api/parceiro',
  assessores: 'core/api/assessor/',
  tags: 'mesas/api/tag_mesa/',
  historico_seguro: 'seguro/api/historico_seguro/',
  acompanhamento_seguro: 'seguro/api/acompanhamento_seguro/',
  acompanhamento_seguro_hub: 'seguro/api/acompanhamento_hub/',
  anotacao_seguro: 'seguro/api/anotacao_seguro/',
  card_seguro: 'seguro/api/mesa_seguro/',
  indicacao_seguro: 'seguro/api/mesa_seguro/',
  notificacoes: 'notificacao/api/mensagem_notificacao/',
  profile: 'home/api/profile/', 
  token: 'api/token/',
  user: 'home/api/user/me/',
  anexo_acompanhamento:'envio_mensagens/'
}

export { api, URLS, API_HEADER }

