<template>
  <q-menu v-model="model" touch-position context-menu>
    <q-list style="min-width: 220px">
      <q-item
        v-close-popup
        clickable
        class="gap-8 items-center text-paragraph-2"
        @click="emit('click:menu', 'open')"
      >
        <q-icon size="1.25rem" class="shrink-0" name="open_in_new"></q-icon>
        Abrir
      </q-item>
      <template
       
      >
        <q-separator />

        <q-item
          v-if="especialCols.ganho"
          v-close-popup
          :disable="disabledBtnGanhoPerda "
          clickable
          class="gap-8 items-center text-paragraph-2 hover:text-alert-success"
          @click="verificacaoMarcarGanho()"
        >
          <q-icon
            size="1.25rem"
            class="shrink-0 text-alert-success"
            name="svguse:#icon_like"
          ></q-icon>
          Marcar Ganho

          
        </q-item>

        <q-item
          v-if="especialCols.perdido"
          v-close-popup
          clickable
          :disable="disabledBtnGanhoPerda"
          class="gap-8 items-center text-paragraph-2 hover:text-alert-error"
          @click="emit('click:menu', 'perda')"
        >
          <q-icon
            size="1.25rem"
            class="shrink-0 text-alert-error"
            name="svguse:#icon_deslike"
            @click="emit('click:menu', 'perda')"
          ></q-icon>
          Marcar Perda
        </q-item>

        <q-separator v-if="especialCols.perdido || especialCols.ganho" />

        <q-item
          v-if="especialCols.arquivado"
          v-close-popup
          clickable
          class="gap-8 items-center text-paragraph-2 hover:text-alert-warning"
          @click="emit('click:menu', 'arquivar')"
        >
          <q-icon
            size="1.25rem"
            class="shrink-0 text-alert-warning"
            name="inventory_2"
          ></q-icon>
          Arquivar
        </q-item>

        <q-item
       
          v-close-popup
          clickable
          class="gap-8 items-center text-paragraph-2 hover:text-primary-pure"
          @click="emit('click:menu', 'cross_sell')"
        >
          <q-icon
            size="1.30rem"
            class="shrink-0 text-primary-pure"
            name="compare_arrows"
          ></q-icon>
          Cross Sell
        </q-item>
      </template>
    </q-list>
  </q-menu>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { NotifyAlert } from 'js/vue/boot/Notify';
import { setGanho } from 'store/mesa.store';

const model = ref(false)
const emit = defineEmits(['click:menu'])
const especialCols = inject('especialCols')
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const mesa = inject('mesa')
const mesaEscolhida = inject('mesaEscolhida')
const isCorporate = inject('isCorporate')
const disabledBtnGanhoPerda = computed(
  () =>
    mesa?.value?.isCambio &&
    !props.data.cliente_cambio_pf &&
    !props.data.cliente_cambio_pj
)

const verificacaoMarcarGanho = () => {
  if((mesa.value.isSeguro || mesaEscolhida.value === 'mesa_seguro') &&
    (!props.data?.empresa_parceira || !props.data?.volume)){
    NotifyAlert('Preencha os dados financeiros (empresa e volume) antes de marcar ganho')
    return false
  }else{
    emit('click:menu', 'ganho')
    return true
  }
}



onMounted(() => {
  // console.log(props.data, 'data')
})
defineExpose({
  model,
})
</script>

<style lang="scss" scoped></style>
