import { URLS } from 'modules/axios'
import useApi from 'composables/useApi'

export function notificacaoService() {
  const api = useApi()

  const deleteNotificacao = async (id) => {
    try {
      await api.mutation(`${URLS.notificacoes}${id}/`,  'DELETE')
      
    } catch (error) {
      console.log(error)
    }
  }

  const patchNotificacao = async (id, dados) => {
    try {
      await api.mutation(`${URLS.notificacoes}${id}/`, 'PATCH', dados)
    } catch (error) {
      console.log(error)
    }
  }

  const getNotificacoes = async (filters = {}) => {
    try {
      const { results } = await api.query(`${URLS.notificacoes}`, filters)
      if(results)
        return results
    } catch (error) {
      console.log(error)
    } 
  }

  return {
    deleteNotificacao,
    patchNotificacao,
    getNotificacoes,
    ...api
  }
}
