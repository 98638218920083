<template>
  <OTable
    ref="tableRef"
    v-bind="attrs"
    v-model:pagination="pagination"
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :filter="searchFilter"
    row-key="id"
    binary-state-sort
    class="table-base"
    primary
    @request="onRequest"
  >
    <template v-if="header" #top>
      <OTableHeaderBase
        :filter="searchFilter"
        :show-rows-per-page="showRowsPerPage"
        :rows-per-page="pagination.rowsPerPage"
        @update="updateModels" 
      />
    </template>

    <template v-for="slot in Object.keys(slots)" #[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps"></slot>
    </template>

    <template #bottom="slotProps">
      <OTableFooterBase
        :downloadable="showDownloadButton"
        :print-button="showPrintButton"
        :rows="rows"
        :columns="columns"
        :slot-props="slotProps"
        :position="positionFooter"
        :pagination="pagination"
        @update="(val) => updatePagination(val)" />
    </template>
  </OTable>

</template>

<script setup>
import { api } from '../../../modules/axios';
import { computed, onMounted, provide, ref, useAttrs, useSlots, watch } from 'vue';
import OTable from './OTable.vue';
import OTableFooterBase from './OTableFooterBase.vue';
import OTableHeaderBase from './OTableHeaderBase.vue';


const attrs = useAttrs()
const slots = useSlots()

const props = defineProps({
  columns: { type: Array, default: () => [] },
  showRowsPerPage:{ type: Boolean, default: true },
  rowsPerPage:{ type: Number , default: 50 },
  header: { type: Boolean, default: true },
  showDownloadButton: { type: Boolean, default: false },
  showPrintButton: { type: Boolean, default: false },
  positionFooter: { type: String, default: 'right' },
  apiUrl: { type: String, required: true }
})

const rows = ref([])
const tableRef = ref()
const searchFilter = ref('')
const loading = ref(true)
const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: props.rowsPerPage,
  rowsNumber: props.rowsPerPage
  
})

function updateTable() {
  tableRef.value?.componentRef.requestServerInteraction()
}

function updateModels(val) {
  
  searchFilter.value = val.filter
  pagination.value.rowsPerPage = val.rowsPerPage
  
  updateTable()
}

function updatePagination(val) {
  console.log('updatePagination')
  pagination.value = val
  updateTable()
}

watch(searchFilter, () => {
  console.log('watch filter')
  pagination.value= {...pagination.value, page: 1}
}, {deep:true})


async function fetchFromServer(startRow, count, search, sortBy, descending) {
  let data = []

  const baseUrl = `${props.apiUrl}&limit=${count}&page=${(startRow/count) + 1}`
 
  const urlWithSearch = `${baseUrl}&search=${search || ''}`

  const response = await api.get(urlWithSearch)
  data = response.data.itens?.slice() || response.data.results?.slice() || response.data.result?.slice()

  return { data, rowsNumber: response.data.total || response.data.count }
}


async function onRequest(requestProps) {
  const { page, rowsPerPage, sortBy, descending } = requestProps.pagination
  const search = requestProps.filter

  loading.value = true
  rows.value = []
  const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
  const startRow = (page - 1) * rowsPerPage
  const returnedData = await fetchFromServer(startRow, fetchCount, search, sortBy, descending)
 
  pagination.value.rowsNumber = returnedData.rowsNumber
  rows.value.splice(0, rows.value.length, ...returnedData.data)

  pagination.value.page = page
  pagination.value.rowsPerPage = rowsPerPage
  pagination.value.sortBy = sortBy
  pagination.value.descending = descending

  loading.value = false
}


provide('pagination', pagination)
onMounted(() => {
  updateTable()
})

defineExpose({ updateTable, tableRows: rows.value, updatePagination, updateModels, pagination, loading })
</script>

<style lang="sass" scoped>
@import "src/css/responsivo.sass"
.table-base
  .q-chip :deep(.q-chip__content)
    justify-content: center
  :deep(.q-btn)
    border: 1px solid rgba(0,0,0,0.1) !important
    .q-icon
      font-size: 20px !important
  :deep(.q-table__middle)
    max-height: 37rem
    flex: none !important

    thead
      position: sticky
      top: 0
      background: rgb(var(--white))
      z-index: 10

      @include d(md)
        th
          font-size: .75rem

.body--dark
  .table-base
    :deep(.q-table__middle)
      border: 1px solid rgba(var(--white), 0.1)

    :deep(thead)
      background: rgb(var(--d-neutral-10))

    :deep(.q-td:not(:last-child))
      border-right: 1px solid rgba(var(--white),0.1)



.body--light
  .table-base
    :deep(.icon-opacity .q-icon)
      color: #999ca4

    :deep(.q-td:not(:last-child))
     border-right: 1px solid rgba(var(--neutral-100),0.1)
</style>
