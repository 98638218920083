<template>
  <section class="relative banner grid grid-cols-[1.3fr_1fr] md:flex p-24">
    <div
      class="ml-[5rem] w-full max-w-[27rem] flex flex-col gap-40 md:ml-0 md2:ml-[2rem]"
    >
      <div class="w-full flex flex-col gap-8">
        <h1 class="text-[48px] font-light text-white">
          {{ parceiroID.razao_social || parceiro.razao_social }}
        </h1>
        <p class="text-headline-1 !font-medium text-white/70">
          Agora você pode atender o seu cliente de forma 360°, blindar a sua
          carteira e aumentar a sua receita sem tirar o foco da sua atividade
          principal.
        </p>
      </div>
      <q-space></q-space>
      <OButton
        secondary
        class="!text-black"
        size="md"
        @click="emits('click:openModalCreateIndicacao')"
        >Faça sua indicação à mesa</OButton
      >
    </div>
    <svg
      class="absolute w-[400px] md2:hidden h-[300px] right-0 left-0 mx-[40%] my-auto top-0 bottom-0 z-20 text-branding-pure"
    >
      <use xlink:href="#icon_section_1"></use>
    </svg>
    <div class="relative h-full md:hidden">
      <img
        :src="newUrlImg('images/introducao.png')"
        alt=""
        class="h-full object-cover"
      />

      <div class="wrapper-image h-full w-full absolute"></div>
    </div>
  </section>
</template>

<script setup>
import { inject } from 'vue';
import { parceiroID } from '../../store/parceiro.store'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

const {parceiro} = inject('context')
const { newUrlImg } = GLOBAL
const emits = defineEmits(['click:openModalCreateIndicacao'])
</script>

<style lang="sass" scoped>
:deep(.q-btn[secondary])
  color: #000000 !important
.banner
  background: linear-gradient(to right, rgba(var(--branding-pure), 1), rgba(var(--branding-pure), 1) 99%)
.wrapper-image
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: linear-gradient(to right, rgba(var(--branding-pure), 1), rgba(var(--branding-pure), 0.1) 60%)
</style>
