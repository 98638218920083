<template>
  <OChatBox
    class="h-full w-full flex-1 mb-0"
    style="box-shadow: initial"
    :comments="comments"
    :send-comment="sendComment"
    :get-comments="getComments"
    :send-anexo="enviarAnexo"
    :is-loading="isLoading"
    tipo="acompanhamento"
    :mesa="state?.data?.id"
    :mesa-hub="state?.data?.id_seguro_hub"
    filters="&page=1&page_size=200"
  />
</template>

<script setup>
import { acompanhamentoSeguroService } from '../../services/acompanhamento_seguro.service'
import { comments } from '../../store/mesa.store';
import {  inject, onMounted, unref } from 'vue'
import Emitter from 'js/vue/boot/Emitter';
import OChatBox from 'components/Chat/OChatBox.vue'

const { sendComment, getComments, isLoading, sendAnexo } = acompanhamentoSeguroService()

const props = defineProps({
  data: Object,
})
const { data } = inject('context')
const {modalKanban} = inject('modal')
const { state } = modalKanban

async function enviarAnexo(file) {
  const formData = new FormData()
  
  formData.append('inptArquivo', unref(file))
  formData.append('mesa_id', state.value.id)
  formData.append('tipo_mesa', 'mesa_seguro')
  formData.append('csrfmiddlewaretoken',data.csrf)
  
  await sendAnexo(formData)
}


onMounted(async () => {
  comments.value = await getComments(state.value.id_seguro_hub)
})

Emitter.on('update:comments', async() => getComments())
</script>

<style lang="scss" scoped></style>
