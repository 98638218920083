<template>
  <q-dialog
    ref="dialogRef"
    transition-hide="fade"
    transition-show="fade"
    transition-duration="200"
  >
    <q-card class="q-dialog-plugin dark:bg-d-neutral-10 p-16 min-w-[320px]">
      <!--  class="flex items-center bg-primary-pure py-16 px-24 text-title-2 text-white" -->
      <header class="text-title-2 text-primary-pure font-medium mb-8">
        {{ title }}
      </header>

      <div class="flex flex-col justify-between min-h-160">
        <FilePicker
          :file-props="fileProps"
          :file="file"
          @update:model-value="(v) => update(v)"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from 'quasar'
import FilePicker from 'components/File/FilePicker.vue'
import OButton from 'components/Button/OButton.vue'

const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const props = defineProps({
  title: String,
  file: Object,
  fileProps: { type: Object, default: () => ({}) },
})

function update(file) {
  onDialogOK(file)
}

defineEmits([...useDialogPluginComponent.emits, 'update'])
</script>
