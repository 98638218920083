<template>
  <q-card
    class="m-16 h-full overflow-hidden card-kanban-list flex flex-col w-auto p-6 flex-nowrap md:mx-0"
  >
    <div class="tabs-wrapper flex gap-8 items-center px-6 pt-6">
      <OButton
        secondary
        size="md"
        :class="`${active === 'todos' ? 'active' : ''}`"
        class="text-neutral-100/70"
        @click="active = 'todos'"
      >
        Todos

        <q-badge
          class="bg-neutral-100/10 text-neutral-100 w-20 h-20 shrink-0 dark:bg-white/10 dark:text-white/80 font-semibold"
          :class="`${
            active === 'todos' ? '!bg-primary-pure/10 !text-primary-pure' : ''
          }`"
          rounded
        >
          <p class="block m-auto">
            {{
              columnsWithCards?.reduce((acc, i) => {
                acc = [...acc, ...i.cards]
                return acc
              }, []).length
            }}
          </p>
        </q-badge>
      </OButton>

      <OButton
        v-for="item in columnsWithCards.sort(ordenateKey('ordem'))"
        :key="item.id"
        secondary
        size="md"
        :class="`${active === item.id ? 'active' : ''}`"
        class="text-neutral-100/70"
        @click="active = item.id"
      >
        {{ item.nome }}
        <q-badge
          class="bg-neutral-100/10 text-neutral-100 w-20 h-20 shrink-0 dark:bg-white/10 dark:text-white/80 font-semibold"
          :class="`${
            active === item.id ? '!bg-primary-pure/10 !text-primary-pure' : ''
          }`"
          rounded
        >
          <p class="block m-auto">{{ item.cards.length }}</p>
        </q-badge>
      </OButton>
    </div>

    <OTable
      v-show="onlyCards?.length"
      :columns="columnsList"
      :rows="onlyCards"
      :filter="filter"
      class="mt-6 my-sticky-header-table w-full flex-[0_1_max-content] !h-0 md:flex-1"
      :pagination="{
        rowsPerPage: 9999,
      }"
    >
      <template #body="props">
        <q-tr
          class="cursor-pointer"
          :props="props"
          @click="() => emit('openModal', props.row)"
        >
          <q-td
            key="nome_cliente"
            :auto-width="false"
            style="max-width: 250px; width: clamp(100px, 20vw, 250px)"
          >
            <div class="one-line">
              {{ props.row.nome_cliente }}
            </div>
          </q-td>

          <q-td key="codigo_assessor" :auto-width="true">
            <div
              v-if="props.row.codigo_assessor"
              class="flex gap-4 text-paragraph-2"
            >
              <span class="">{{ props.row.codigo_assessor }}</span>
            </div>
            <div v-else>-</div>
          </q-td>
          <q-td key="nome_assessor" :auto-width="true">
            <div
              v-if="props.row.assessor_serializado?.nome"
              class="flex gap-4 text-paragraph-2"
            >
              <span class="">{{ props.row.assessor_serializado?.nome }}</span>
            </div>
            <div v-else>-</div>
          </q-td>

          <q-td key="tag" :auto-width="true">
            <div class="flex gap-4 max-w-[70px] w-max items-center">
              <TagBase
                v-if="props.row.produto"
                :nome="props.row.produto"
                :cor="props.row.cores.cor_letra"
                class="w-max h-8"
              />
              <TagBase
                v-for="tag in props.row.tags"
                :key="tag.id"
                :tag="tag"
                :nome="tag.nome"
                :cor="tag.cor_letra"
                class="w-max h-8"
              />
            </div>
          </q-td>
        </q-tr>
      </template>
    </OTable>
    <div
      v-show="!onlyCards?.length"
      class="flex flex-col items-center ml-8 -mt-16 gap-8 opacity-40 justify-center m-0 flex-1"
    >
      <q-icon class="block opacity-30" name="atr" size="2.5rem"></q-icon>
      <p class="text-paragraph-2">Coluna vazia</p>
    </div>
  </q-card>
</template>

<script setup>
import { columnsList } from './columns.js'
import { computed, inject, onMounted, ref } from 'vue'
import GLOBAL from 'js/utils/GLOBAL'
import OButton from 'components/Button/OButton.vue'
import OTable from 'components/Table/OTable.vue'
import TagBase from 'components/Tag/TagBase.vue'

const active = ref('todos')
const filter = ref('')
const columnsWithCards = inject('listaCards')
const { ordenateKey, FData } = GLOBAL
const emit = defineEmits(['openModal'])

const onlyCards = computed(() =>
  columnsWithCards?.value.reduce((acc, i) => {
    const cards = i.cards.filter((i) => {
      if (active.value === 'todos') return i
      return i.coluna_kanban === active.value
    })
    acc = [...acc, ...cards]
    return acc
  }, [])
)

</script>

<style lang="sass" scoped>
@import "src/css/responsivo.sass"

.my-sticky-header-table
  :deep(.q-table__bottom)
    display: none

@include d(md)
  .tabs-wrapper
    touch-action: pan-x
    overflow-x: auto
    scroll-snap-type: x mandatory
    flex-wrap: nowrap
    height: max-width
    min-height: 48px !important
    padding-top: 0

    &::-webkit-scrollbar
      display: none

    :deep(.q-btn)
      min-width: max-content !important
      height: 2rem
      font-size: .75rem !important
      min-height: 0 !important
      padding: 0 .375rem !important

      .q-badge
        font-size: .625rem
</style>
