<template>
  <header class="h-64 bg-primary-pure flex items-center px-24 w-full">
    <div class="mr-24">
      <h4 class="text-headline-1 text-white">{{ title }}</h4>
    </div>

    <q-space></q-space>

    <OButtonRounded class="text-white" @click="emit('click:close')" />
  </header>
</template>

<script setup>
import OButtonRounded from 'components/Button/OButtonRounded.vue'

const emit = defineEmits(['click:close'])

const props = defineProps({ title: String })
</script>

<style lang="scss" scoped></style>
