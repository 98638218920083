<template>
  <section class="flex flex-col gap-40 mx-auto">
    <div class="grid grid-cols-[1.2fr_1.4fr] md:grid-cols-1 gap-24">
      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">Plano de Saúde</h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100">Plano de Saúde </span> é
          a garantia de acesso a cuidados médicos de qualidade, proporcionando
          tranquilidade e segurança para você e sua família. Com diversas opções
          de cobertura, o plano de saúde se adapta às suas necessidades,
          oferecendo desde consultas e exames até internações e tratamentos
          especializados. A saúde é um bem precioso, e um plano adequado é
          fundamental para proteger você e seus entes queridos em todos os
          momentos.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            size="md"
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton
            secondary
            size="md"
            icon="svguse:#icon_download"
            @click="emits('click:download', data.documento_plano_saude)"
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>

      <CardImageRight
        :image="newUrlImg('images/seguro_saude.png')"
        icon-name="svguse:#icon_detail_saude"
      />
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.text }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageRight from './CardImageRight.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { inject } from 'vue'

const { newUrlImg } = GLOBAL
const {data} = inject('context')
const emits = defineEmits(['click:openModalCreateIndicacao', 'click:download'])
const modalidades = [
  {
    title: 'Plano Ambulatorial',
    text: 'Cobertura para consultas, exames e tratamentos realizados em ambulatório, garantindo acesso rápido a cuidados médicos sem internação.',
  },
  {
    title: 'Plano Hospitalar',
    text: 'Proteção completa para internações hospitalares, incluindo cirurgias e tratamentos mais complexos, assegurando atendimento em momentos críticos.',
  },
  {
    title: 'Plano Hospitalar com Obstetrícia',
    text: 'Além da cobertura hospitalar completa, inclui assistência para gestantes, garantindo todo o suporte durante a gravidez e o parto.',
  },
  {
    title: 'Plano Referência',
    text: 'A opção mais completa, que abrange atendimentos ambulatoriais e hospitalares, com ou sem obstetrícia, proporcionando cobertura integral em todas as situações de saúde.',
  },
  {
    title: 'Plano Odontológico',
    text: 'Cuidados dentários essenciais, incluindo consultas, tratamentos e procedimentos odontológicos, para manter sua saúde bucal em dia.',
  },
]
</script>

<style lang="scss" scoped></style>
