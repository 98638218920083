<template>
  <footer
    class="flex items-center justify-between w-full mt-12 table-footer-base md:gap-16 md:flex-col md:items-start md:mt-16">
    <div class="flex gap-8 md:flex-col md:w-full">
      <OButton
        v-if="downloadable"
        icon="svguse:#icon_excel"
        size="sm"
        class="md:w-full dark:bg-white/10 dark:!border-transparent icon-opacity"
        secondary
        @click="() => exportTable(props.columns, props.rows)">
        Baixar tabela
      </OButton>

      <OButton
        v-if="printButton"
        icon="svguse:#icon_print"
        size="sm"
        class="md:w-full dark:bg-white/10 dark:!border-transparent icon-opacity"
        secondary
        @click="() => exportTable(props.columns, props.rows)">
        Imprimir
      </OButton>
    </div>

    <div class="pagination-wrapper" :position="props.position">
      <OButton
        icon="chevron_left"
        :disable="props.slotProps.isFirstPage"
        class="rounded-[21px] !min-w-[6rem]"
        secondary
        size="sm"
        @click="goPrev()">
        Anterior
      </OButton>

      <q-pagination
        v-model="paginationFooter.page"
        color="primary"
        :max="pagesNumber"
        :max-pages="4"
        :boundary-numbers="false"

        round
        @update:model-value="$emit('update', pagination)" />

      <OButton
        icon-right="chevron_right"
        :disable="props.slotProps.isLastPage"
        class="rounded-[21px] !min-w-[6rem]"
        secondary
        size="sm"
        @click="goNext()">
        Próximo
      </OButton>
    </div>
  </footer>
</template>

<script setup>
import { computed, ref } from 'vue'
import GLOBAL from 'utils/GLOBAL'
import OButton from 'components/Button/OButton.vue'

const { exportTable } = GLOBAL

const emit = defineEmits(['update'])

const props = defineProps({
  rows: { type: Array, default: () => [] },
  columns: { type: Array, default: () => [] },
  slotProps: { type: Object, default: () => ({}) },
  pagination: { type: Object, default: () => ({}) },
  downloadable: { type: Boolean, default: false },
  position: { type: String, default: 'right' },
  printButton: { type: Boolean, default: false },
})

const paginationFooter = ref(props.pagination)

const pagesNumber = computed(() => {
  const total = props.pagination?.rowsNumber || props.rows.length
  return Math.ceil(total / props.pagination.rowsPerPage)
})

function goNext() {
  props.slotProps.nextPage()
  paginationFooter.value.page++
}

function goPrev() {
  props.slotProps.prevPage()
  paginationFooter.value.page--
}
</script>

<style lang="sass" scoped>

.table-footer-base
  .q-pagination
    :deep(.q-btn)
      width: 2.25rem !important
      height: 2.25rem !important
      border: 1px solid rgba(var(--neutral-100),0.1)
      font-size: .75rem
      margin: 0

      &:before
        box-shadow: initial !important

    :deep(.q-pagination__middle),
    :deep(.q-pagination__content)
      gap: .375rem

.body--light
  .table-footer-base
    :deep(.icon-opacity .q-icon)
      color: #999ca4
    :deep(.q-btn.disabled)
      opacity: 1 !important
      background: rgba(var(--neutral-100), 0.1)
      color: rgba(var(--neutral-100), 0.4)
    .q-pagination :deep(.q-btn)
      color: rgb(var(--neutral-70)) !important
      &.bg-primary
        color: rgb(var(--white)) !important
        border: transparent

.body--dark
    
  .table-footer-base
    ::v-deep .q-icon
      color: #ffffff !important
    :deep(.q-table__middle)
      background: rgb(var(--d-neutral-40))
      border-bottom: 1px solid rgba(var(--white), 0.1)
    :deep(.q-btn.disabled)
      opacity: 1 !important
      background: rgba(var(--white), 0.05)
      color: rgba(var(--white), 0.2)
    .q-pagination :deep(.q-btn)
      border: 1px solid rgba(var(--white),0.1)
      color: rgba(var(--white), 0.7) !important
      background: rgba(var(--white), 0.05)
      &.bg-primary
        color: rgb(var(--neutral-100)) !important
  
    :deep(.q-btn__content)
      color: rgba(var(--white),0.9) !important

.pagination-wrapper
  display: flex
  align-items: center
  gap: .5rem
  padding-bottom: 0.5rem
  &[position='right']
    margin-left: auto
  &[position='center']
    margin-left: auto
    margin-right: auto
  @media (max-width: 1024px)
    margin-left: 0
    padding-bottom: .5rem
</style>
