<template>
  <header
    class="!px-8 py-16 !static flex flex-wrap items-center gap-8 bg-white min-h-[var(--second-top-menu)] border-y border-y-neutral-40"
  >
    <p class="text-title-3 text-primary-pure !font-medium">
      Mesa Seguro
    </p>
    <slot name="right-0"></slot>
    <q-space />

    <slot name="right-1"></slot>

    <OButton v-if="!hideFilter" size="md" secondary>
      <OBadgeFilter :qnt="filterRef?.qnt" />
      <q-icon name="filter_list"></q-icon>
      Filtros
      <FilterKanban
        ref="filterRef"
        @click:confirm="(q) => emit('click:filter:confirm', q)"
        @click:remove="(q) => emit('click:filter:remove', q)"
      />
    </OButton>

    <slot name="right-2"></slot>
  </header>
</template>

<script setup>
import {  ref } from 'vue'
import FilterKanban from 'components/Filter/FilterKanban.vue'
import OBadgeFilter from 'components/Badge/OBadgeFilter.vue'
import OButton from 'components/Button/OButton.vue'

const {mesa,data, hideFilter} = defineProps({
  data: { type: Object, default: () => ({}) },
  mesa: { type: Object, default: () => ({}) },
  hideFilter:{type: Boolean, default: false}
})


const filterRef = ref(null)

const emit = defineEmits(['click:filter:confirm', 'click:filter:remove'])

defineExpose({
  filterRef,
})
</script>

<style lang="scss" scoped></style>
