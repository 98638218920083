import { NotifySucess } from "../boot/Notify"
import { URLS } from "js/modules/axios"
import useApi from "composables/useApi"

export function anotacoesService(){
  const api = useApi()
  const getAnotacoes = async (idMesa) => {
    try {
      const {results} = await api.query(URLS.anotacao_seguro , { indicacao_mesa: idMesa })
      return results ?? []
    } catch (error) {
      
      console.log(error)
    }
  }


async function postAnotacao(dados) {
  try {
    const {texto, indicacao_mesa, kanban_corporate} = dados
     await api.mutation(URLS.anotacao_seguro, 'POST', {
      texto,
      indicacao_mesa,
      kanban_corporate,
    })
    NotifySucess('Anotação salva com sucesso')
  } catch (error) {
    console.log(error)
  } 
}
  
async function postAnotacaoAnexo(dados) {
  try {
      const {file, indicacao_mesa, kanban_corporate} = dados
    const formData = new FormData()
    formData.append('anexo_anotacao', file)
    formData.append('indicacao_mesa', indicacao_mesa)
    formData.append('kanban_corporate', kanban_corporate)
    await api.mutation(URLS.anotacao_seguro, 'POST', {file, indicacao_mesa, kanban_corporate})
    NotifySucess('Anotação salva com sucesso')
  } catch (error) {
    console.log(error)
  } 
}

  return{getAnotacoes,postAnotacao, postAnotacaoAnexo, ...api}
}
