<template>
  <ModalBase :open="open" :state="state" :close="close">
    <template #content>
     <div class="flex flex-col gap-8">
      <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tristique,
      odio at tempus elementum, dui dui mattis turpis, et malesuada metus metus
      posuere ipsum. Cras dictum, eros ac scelerisque feugiat, erat nibh
      elementum velit, at ullamcorper enim urna vitae velit. Nam id turpis
      pulvinar, mattis arcu nec, interdum sem. Praesent mollis interdum
      bibendum. Quisque velit mi, volutpat sed nisl vel, blandit fringilla sem.
      Nunc sed ligula non elit ornare maximus. Sed rhoncus egestas lacinia.
      Etiam at augue ac nibh consequat lacinia. Etiam quis fermentum mauris.
      Maecenas volutpat, neque ac sodales elementum, turpis neque mattis arcu,
      id euismod lectus sem et urna. Sed et faucibus erat, ac faucibus risus.</p>
      <p>
         Duis in lobortis elit. Nullam semper lacus vitae felis porta, eget
      pellentesque ligula lacinia. Praesent vestibulum turpis libero, pharetra
      ornare ligula varius ut. Phasellus finibus vel urna id luctus. Integer a
      cursus lectus, non mollis risus. Etiam consectetur arcu ut magna
      consequat, id blandit dui tempor. Curabitur facilisis, ligula vitae
      vehicula aliquam, ante leo lobortis diam, et mollis est sapien vel metus.
      Vestibulum euismod molestie quam, a imperdiet metus efficitur quis. Morbi
      ac cursus sapien, ac malesuada ipsum. Fusce vel arcu erat. Duis sagittis
      hendrerit tortor, ac consectetur nunc scelerisque eget. Vivamus facilisis
      enim id magna pellentesque venenatis. Sed quis erat est. Aliquam vel massa
      in dui ultricies ultrices ac et elit. Pellentesque congue massa ut justo
      elementum, vel scelerisque elit ultrices. 
      </p>
     </div>
     
    </template>
  </ModalBase>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import ModalBase from './ModalBase.vue'
const { modalPoliticas } = inject('modal')
const { open, state, close, setState } = modalPoliticas

onMounted(() => {
  setState({ header: { title: 'Políticas e termos' }, style:{width: '900px'} })
})
</script>

<style lang="scss" scoped></style>
