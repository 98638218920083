<template>
  <fieldset class="flex-1 flex flex-col gap-4">
    <span class="text-neutral-60 text-paragraph-2">{{ label }}</span>
    <p v-if="!edit || !dataKey" class="text-paragraph-2 text-neutral-100">
      {{ textField }}
    </p>

    <OInput
      v-else-if="dataKey && !isSelect && type !== 'date'"
      :model-value="edicaoObj[dataKey]"
      :data-key="keyInput"
      size="md"
      bg-color="neutral-10"
      v-bind="inputProps"
      @update:model-value="
        (newValue) => {  
          GLOBAL.debounce(debounceTime, updateValue, 'timeModelValue')(newValue)
        }
      "
    />
    <OInputDateTime
      v-else-if="dataKey && !isSelect && type === 'date'"
      :data="edicaoObj[dataKey]"
      :data-key="keyInput"
      size="md"
      bg-color="neutral-10"
      v-bind="inputProps"
      @update:date="
        (newValue) => {
          if (dataKey)
            if (isNumber) {
              edicaoObj[dataKey] = String(newValue).replace(',', '.')
            } else {
              edicaoObj[dataKey] = newValue
            }
          emit('patch:mesa')
        }
      "
    />

    <OSelect
      v-else-if="dataKey && isSelect"
      :model-value="edicaoObj[dataKey]"
      :data-key="keyInput"
      size="md"
      emit-value
      map-options
      bg-color="neutral-10"
      v-bind="inputProps"
      @update:model-value="
        (newValue) => {
          if (dataKey) edicaoObj[dataKey] = newValue
          emit('patch:mesa')
        }
      "
    />

  </fieldset>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue'
import FilePicker from 'components/File/FilePicker.vue'
import GLOBAL from 'js/utils/GLOBAL'
import OInput from 'components/Input/OInput.vue'
import OInputDateTime from 'components/Input/OInputDateTime.vue'
import OSelect from 'components/Select/OSelect.vue'

const {
  text,
  sending,
  dataKey,
  isSelect,
  isNumber,
  valueMostrar,
  type,
 isMoney
} = defineProps({
  label: String,
  text: String,
  isNumber: { type: Boolean, default: false },
  valueMostrar: String,
  keyInput: String,
  sending: Boolean,
  dataKey: String,
  isSelect: { type: Boolean, default: false },
  isMoney: { type: Boolean, default: false },
  inputProps: { type: Object, default: () => ({}) },
  type: { type: String, default: '' },
  debounceTime: { type: Number, default: 900 },
})

const emit = defineEmits(['patch:mesa'])
const edit = inject('editar')
const edicaoObj = inject('edicaoObj')

const textField = computed(() => {
  if(isMoney){
    return text ? GLOBAL.FMoney(text) : '-'
  }else if (isNumber) {
    return text ? String(text)?.replace('.', ',') : '-'
  } 
  else if (type === 'date') {
    return GLOBAL.FData(text)
  } else {
    return valueMostrar || text || '-'
  }
})



watch(
  () => text,
  (v) => {
    let value = v
    if (isNumber) {
      value = text ? String(text)?.replace('.', ',') : ''
    }
    if (dataKey) edicaoObj.value[dataKey] = value
  },
  { deep: true, immediate: true }
)

const updateValue = (newValue) => {
  if (dataKey) edicaoObj.value[dataKey] = newValue

  emit('patch:mesa')
}
</script>

<style lang="scss" scoped></style>
