import { createApp } from 'vue'
import { Dialog, Notify, Quasar } from 'quasar'
import { NotifyInit } from './boot/Notify'
import LayoutBase from 'layouts/LayoutBase.vue'
import quasarPT from 'quasar/lang/pt-BR'
import router from './router/router'
import useModal from 'composables/useModal'

NotifyInit()

const context = {
  ...window.context,
}
const modalKanbanIndicacaoCreate = useModal({})
const modalKanban = useModal({})
const modalKanbanSide = useModal({})
const modalPoliticas = useModal({})
const app = createApp(LayoutBase)

app.provide('context', context)
app.provide('modal', { modalKanbanIndicacaoCreate,modalKanban,modalKanbanSide, modalPoliticas })

app.config.globalProperties.$console = console.log

app.use(router)

app.use(Quasar, {
  plugins: {
    Notify,
    Dialog,
  },
  lang: quasarPT,
  config: {
    notify: {},
    screen: {
      bodyClasses: true,
    },
    ripple: {
      early: true,
    },
  },
})

app.mount('#app')

export default app
