<template>
  <q-popup-edit
    ref="popupUpProjeto"
    anchor="top left"
    class="w-[26.875rem] p-8"
    :model-value="projetoSelected"
    @show="selectShow"
  >
    <p class="mb-16 text-title-5">{{ text }}</p>

    <OSelect
      v-if="type === 'tag'"
      v-bind="selectProps"
      ref="select"
      v-model="projetoSelected"
      use-input
      size="md"
      behavior="menu"
      :option-value="optionValue"
      :option-label="optionLabel"
      :options="opt"
      :label="selectLabel"
      :auto-save="true"
      @update:model-value="handleEmit"
    >
      <template v-for="slot in Object.keys(slots)" #[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps"></slot>
      </template>

      <template #option="{ itemProps, opt: option, selected: isSelected, toggleOption }">
        <q-item v-bind="itemProps">
          <q-item-section>
            <q-item-label class="flex items-center gap-8">
              <q-badge
                rounded
                class="shrink-0 w-8 h-8"
                :style="{ background: option.cor_letra }"
              ></q-badge>
              <p class="!text-neutral-70 dark:!text-white/90">{{ option.nome }}</p>
            </q-item-label>
          </q-item-section>
          <q-item-section side class="!w-56">
            <q-toggle
              size="sm"
              :model-value="isSelected"
              @update:model-value="toggleOption(option)"
            />
          </q-item-section>
        </q-item>
      </template>
    </OSelect>

    <OSelect
      v-else
      ref="select"
      use-input
      size="md"
      class="w-full"
      :model-value="projetoSelected"
      :loading="!opt"
      clearable
      foto-key="logo"
      :options="opt"
      :label="selectLabel"
      :option-value="optionValue"
      :option-label="optionLabel"
      v-bind="selectProps"
      @updateValue="handleEmit"
      @clear="handleEmit"
    >
    </OSelect>
  </q-popup-edit>
</template>
