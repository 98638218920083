import { comments } from "../store/mesa.store"
import { NotifyError } from "../boot/Notify"
import { URLS } from "../../modules/axios"
import useApi from "../composables/useApi"


export function acompanhamentoSeguroService() {
  const api = useApi()

  async function getComments(idMesa) {
    
    try {
      const data = await api.query(URLS.acompanhamento_seguro_hub + `${idMesa}/`)
      comments.value = data?.results || []
      return data?.results || []
    } catch (error) {
      console.log(error)
      NotifyError('Um Erro Aconteceu')
    } 
  }


async function sendAnexo(dados) {
  try {
    
    const response = await api.mutation(URLS.anexo_acompanhamento, 'POST', dados)
  
    return response
  } catch (e) {
    NotifyError('Um Erro Aconteceu')
    return null
  }
}
  
  
async function sendComment(dados) {
  try {
    const { mensagem, mesa } = dados

    const { data } = await api.mutation(URLS.acompanhamento_seguro, 'POST', {
      mensagem,
      mesa,
    })

    return data
  } catch (e) {
    console.log(e)
    NotifyError('Um Erro Aconteceu')
    return null
  } 
}
  
  return {getComments, ...api, sendComment, sendAnexo}
}
