import { URLS } from "../../modules/axios";
import useApi from "../composables/useApi";

export function profileService() {
  const api = useApi()
  
  const patchProfile = async(id,dados) => {
     try {
      const response = await api.mutation(`${URLS.profile}${id}/`, 'PATCH', dados)
      return response
    } catch (error) {
      console.log(error)
    
    } 
  }

  const getProfileUserId = async (idUser) => {
    try {
      const  data  = await api.query(`${URLS.profile}`, { usuario: idUser })
      if (data.results.length > 0) {
        return data.results[0]
      }
    } catch (error) {
      console.log(error)
    
    } 
  }
  return {patchProfile, getProfileUserId, ...api}
}
