<template>
  <q-layout view="hHh Lpr fFf" class="overflow-hidden">
    <template v-if="!isLoginPage ">
      <BaseHeader> </BaseHeader>
      <!-- <MenuMultiLevel /> -->
    </template>

    <main :class="{'page-container': !isLoginPage}">
      <router-view></router-view>
    </main>
    
  </q-layout>
</template>

<script setup>
import { computed } from 'vue'
import { profile } from '../store/profile.store'
import { useRoute } from 'vue-router'
import BaseHeader from '../components/Header/BaseHeader.vue'
import MenuMultiLevel from '../components/MenuMultiLevel/MenuMultiLevel.vue'

const route = useRoute()
const isLoginPage = computed(() => route.name === 'login')
const isKanbanPage = computed(() => route.name === 'kanban')
</script>

<style lang="sass" scoped>
.page-container
  --Nv0-sidebar-width: 0px
  --second-top-menu: 3.5rem
  --header-bg: white
  --top-size:  var(--header-base-height)
  margin-left: var(--Nv0-sidebar-width)
  margin-top: var(--header-base-height)
  height: calc(100vh - var(--top-size) - var(--menu-bottom-height))
  flex-direction: column
  display: flex
  overflow-y: auto
</style>
