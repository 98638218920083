<template>
  <div class="grid grid-cols-3 gap-16 mt-16">
    <KanbanModalFieldset
      data-key="numero_proposta"
      label="Número da proposta"
      :text="data.numero_proposta"
      @patch:mesa="() => onEmit()"
    />
    <KanbanModalFieldset
      data-key="numero_apolice"
      label="Número da apólice"
      :text="data.numero_apolice"
      @patch:mesa="() => onEmit()"
    />
  </div>

  <q-separator class="-mx-24 mt-16" />
  <div class="grid grid-cols-3 gap-16 mt-16">
    <KanbanModalFieldset
      type="date"
      label="Data de vigência (Início)"
      :text="data.inicio_vigencia"
      data-key="inicio_vigencia"
      @patch:mesa="() => onEmit()"
    />
    <KanbanModalFieldset
      type="date"
      label="Data de vigência (Fim)"
      :text="data.fim_vigencia"
      data-key="fim_vigencia"
      @patch:mesa="() => onEmit()"
    />
     <KanbanModalFieldset
      :is-select="true"
      data-key="periodicidade_pagamento"
      label="Periodicidade do pagamento"
      :text="data.periodicidade_pagamento"
      :value-mostrar="data.periodicidade_pagamento"
      :input-props="{
        options: [{label:'anual', value: 'Anual'}, {label:'mensal', value: 'Mensal'}],
        'option-label': 'label',
        'use-input': true,
        'popup-content-class': 'my-class-select',
      }"
      @patch:mesa="() => onEmit()"
    />
  </div>
  <q-separator class="-mx-24 mt-16" />
  <div class="grid grid-cols-3 gap-16 mt-16">
    <FilePicker
      :file-props="{accept:'.pdf'}"
      :file="file"
      
      @update:model-value="(v) => update(v)"
    />
  
  </div>
</template>

<script setup>
import { getEmpresasParceiras } from 'store/empresasParceiras.store.js'
import { onMounted, ref } from 'vue'
import FilePicker from 'components/File/FilePicker.vue'
import KanbanModalFieldset from './KanbanModalFieldset.vue'

const emit = defineEmits(['patch:mesa:financeiro'])
const file = ref()
const empresasParceiras = ref([])

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  tipo: { type: String, default: '' },
})

function update(fileParam) {
  file.value = fileParam
}

function onEmit() {
  emit('patch:mesa:financeiro')
}
const moneyFormatForDirective = {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: true,
}
onMounted(async () => {
  empresasParceiras.value = await getEmpresasParceiras()
})
</script>
<style lang="sass">
.my-class-select
  max-height: 300px
  height:  300px
  overflow-y: auto !important
</style>
