import {api} from 'modules/axios'
import { computed, nextTick, ref } from 'vue'
import GLOBAL from 'js/utils/GLOBAL'

const especialCols = ref({
  arquivado: null,
  ganho: null,
  perdido: null,
})

function createColumnsWithCards(cols, cards) {
  return (
    cols?.map((col) => {
      col.cards = cards.filter((card) => card.coluna_kanban === col.id)
      return col
    }) || []
  )
}

export default function useKanban(urls) {
  const cols = ref([])
  const cards = ref([])

  const columnsWithCards = computed({
    get() {
      return createColumnsWithCards(cols.value, cards.value)
    },
    set(newValue) {
      cards.value = newValue.reduce((acc, item) => {
        acc = [...acc, ...item.cards]
        return acc
      }, [])

      cols.value = newValue.reduce((acc, item) => {
        delete item.cards
        acc.push(item)
        return acc
      }, [])

      return newValue
    },
  })

 
  // remove arquivado ganho e perda da lista
  function reduceCols(acc, item) {
    // acc.push(item)
    if (item.arquivado) especialCols.value.arquivado = item.id
    else if (item.ganho) especialCols.value.ganho = item.id
    else if (item.perdido) especialCols.value.perdido = item.id
    else acc.push(item)

    return acc
  }


  const removeEventsWrapper = ref(false)
  const enableDragScroll = GLOBAL.enableDragScroll(removeEventsWrapper)
  const drag = ref(false)

  async function getCols(key = 'mesa_seguro', reduceBoolean = true) {
    try {
      const url = urls[key]
      const response = await api.get(url.cols)
     
      await nextTick()
      if (reduceBoolean && response?.data?.results) {
        cols.value = response?.data?.results.reduce(reduceCols, [])
      } else {
        cols.value = response?.data?.results
      }
    } catch (error) {
      console.log(error)
      
    }
  }
  
  async function getCards(id, key, filters) {
    const url = urls[key]

    try {
      const { data } = await api.get(url.cards+`&coluna_kanban=${id}`+`${filters.replace('?', '&')}`)

      cards.value =  data.results
    } catch (error) {
      console.log(error)
    } 
  }
  return {
    columnsWithCards,
    cols,
    cards,
    removeEventsWrapper,
    enableDragScroll,
    drag,
    especialCols,
    reduceCols,
    getCols,
    getCards,
    createColumnsWithCards,
  }
}
