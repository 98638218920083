<template>
  <q-card v-if="!loading" class="p-8 overflow-hidden pb-0">
    <p class="text-caps-2 text-neutral-60 mb-6">Dados do cliente</p>
    <div class="grid grid-cols-2 gap-16">
      <div class="flex flex-col gap-8">
        <div class="flex !row-span-1 flex-col">
          <p
            v-if="!editar || tabs !== 'resumo'"
            class="text-neutral-100 text-paragraph-2 !font-medium one-line"
          >
            {{ data.nome_cliente || data.nome_completo || '-' }}
          </p>
          <KanbanModalFieldset
            v-else-if="tabs === 'resumo'"
            :text="data.nome_cliente"
            :data-key="isDataKey ? 'nome_cliente' : ''"
            :debounce-time="2000"
            class="!h-40"
            :class="editar ? 'input-edit' : ''"
            :input-props="{
              'bg-color': 'white',
              size: 'md',
              autofocus: true,
            }"
            @patch:mesa="() => onEmit()"
          />
          <!--  <p class="text-neutral-60 text-paragraph-2">
          {{ data?.codigo_cliente || '-' }}
          
        </p> -->
        </div>
        <div>
          <span
            class="text-paragraph-2 flex flex-col gap-2 text-neutral-60 !font-medium"
            >Contato na empresa:
          </span>

          <KanbanModalFieldset :text="data.nome_contato_empresa" />
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <div
          class="flex items-center gap-4 text-neutral-100 cursor-pointer !font-medium"
          @click="data.telefone && !editar && copyAndAlert(data.telefone)"
        >
          <q-icon name="call" size="1.25rem" />
          <span v-if="!editar || tabs !== 'resumo'">{{
            data.telefone || '-'
          }}</span>

          <!-- {{ phone }} -->
          <KanbanModalFieldset
            v-else-if="tabs === 'resumo'"
            :text="data.telefone"
            :data-key="isDataKey ? 'telefone' : ''"
            :class="editar ? 'input-edit ' : ''"
            :input-props="{
              'bg-color': 'white',
            }"
            @patch:mesa="() => onEmit()"
          />
        </div>
        <div
          class="!inline-flex items-center flex-nowrap gap-4 text-neutral-100 cursor-pointer !font-medium"
          @click="data.email && !editar && copyAndAlert(data.email)"
        >
          <q-icon name="mail" size="1.25rem" />
          <span v-if="!editar || tabs !== 'resumo'">{{
            data.email || '-'
          }}</span>

          <!-- {{ email }} -->
          <KanbanModalFieldset
            v-else-if="tabs === 'resumo'"
            :text="data.email"
            :data-key="isDataKey ? 'email' : ''"
            :input-props="{
              type: 'email',
              'bg-color': 'white',
            }"
            :class="editar ? 'input-edit' : ''"
            :debounce-time="2000"
            @patch:mesa="() => onEmit()"
          />
        </div>
      </div>
    </div>
    <q-separator class="-mx-8 mt-8" />

    <div class="grid grid-cols-2 py-8 gap-6">
      <div
        class="text-paragraph-2 flex flex-col gap-2 text-neutral-60 !font-medium"
      >
        Código XP:
        <span v-if="!editar || tabs !== 'resumo'">{{ data.codigo_XP }}</span>

        <KanbanModalFieldset
          v-else-if="tabs === 'resumo'"
          :text="data.codigo_XP"
          :data-key="isDataKey ? 'codigo_XP' : ''"
          :input-props="{
            rules: bancosParceiros,
            'bg-color': 'white',
          }"
          :class="editar ? 'input-edit' : ''"
          :debounce-time="2000"
          @patch:mesa="() => onEmit()"
        />
      </div>

      <div class="text-paragraph-2 flex flex-col text-neutral-60 !font-medium">
        Possui conta PJ na XP?
        <span v-if="!editar || tabs !== 'resumo'">{{ botaoXP }}</span>
        <KanbanModalFieldset
          v-else-if="tabs === 'resumo'"
          :text="data.botao_XP ? 'True' : 'False'"
          :data-key="isDataKey ? 'botao_XP' : ''"
          :debounce-time="2000"
          :is-select="true"
          :value-mostrar="botaoXP"
          :input-props="{
            options: optsXP,
            'bg-color': 'white',
          }"
          :class="editar ? 'input-edit' : ''"
          @patch:mesa="() => onEmit()"
        />
      </div>
    </div>
  </q-card>
  <q-skeleton
    v-else
    v-once
    width="100%"
    height="140px"
    class="mb-16"
  ></q-skeleton>
</template>

<script setup>
import { computed, inject, onMounted, provide, ref } from 'vue'
import GLOBAL from 'utils/GLOBAL.js'
import KanbanModalFieldset from 'components/Kanban/KanbanModalFieldset.vue'

const { copyAndAlert } = GLOBAL

const props = defineProps({
  data: { type: Object, default: () => ({}) },
  loading: { type: Boolean, default: true },
})
const botaoXP = computed(() => (props.data.botao_XP ? 'Sim' : 'Não'))

const emits = defineEmits(['patch:mesa'])
const editar = inject('editar')
const optsXP = ref([
  { value: 'True', label: 'Sim' },
  { value: 'False', label: 'Não' },
])

const mesa = inject('mesa')
const tabs = inject('tabs')
const isDataKey = computed(() => {
  if (
    (mesa.value.isSeguro || mesa.value.mesaEscolhida === 'mesa_seguro') &&
    tabs.value === 'resumo'
  ) {
    return true
  }
  return false
})

const onEmit = () => {
  emits('patch:mesa')
}

</script>

<style lang="sass">
.input-edit
  .q-field

    height: 40px !important

    &:hover
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1)
  .q-field.size-md .q-field__control
    border: 1px solid rgb(229, 230, 235)
</style>
