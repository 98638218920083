<template>
  <article
    class="kanban-card border border-neutral-40 rounded-md bg-white relative group hover:border-neutral-100/30"
    :class="{
      '!border-primary-pure': menuRef?.model,
      '!border-primary-pure shadow-md': card.active,
    }"
    :data-id="card.id"
    :is-move="card.cliente_ativo"
    @click="$emit('click', card)"
  >
    <header
      class="flex flex-col border-b border-b-neutral-40 items-center px-8 py-12 gap-8 flex-nowrap"
    >
      <div class="flex flex-wrap gap-8 mb-6 w-full">
        <ObadgeTag
          v-if="card.cross_sell"
          size="sm"
          :color="returnRGB(card.tag_cross_sell.cor_letra)"
          :badgecolor="returnRGB(card.tag_cross_sell.cor_fundo)"
          :badge="true"
          square
        >
          <template #content>Cross Sell</template>
        </ObadgeTag>
        <ObadgeTag
          v-if="card.produto"
          size="sm"
          :color="returnRGB(card.cores.cor_letra)"
          :badgecolor="returnRGB(card.cores.cor_fundo)"
          :badge="true"
          square
        >
          <template #content>{{ card.produto }}</template>
        </ObadgeTag>
        <ObadgeTag
          v-for="tag in card?.tags"
          :key="tag.id"
          size="sm"
          :color="returnRGB(tag.cor_letra)"
          :badgecolor="returnRGB(tag.cor_fundo)"
          :badge="true"
          square
        >
          <template #content>{{ tag.nome }}</template>
        </ObadgeTag>
      </div>
      <div class="flex items-center justify-between w-full gap-8 flex-nowrap">
        <p
          class="text-paragraph-2 text-medium text-neutral-60 whitespace-nowrap"
        >
          {{ card.id }}
        </p>
        <q-space />
        
        <p
        
          class="text-paragraph-2 text-primary-pure !font-medium line-clamp-1"
          :title="card.nome_cliente"
        >
          {{ card.nome_cliente || card.nome_investidor || card.nome_completo}}
        </p>
      </div>
    </header>

    <section class="px-8 py-12">
      <div class="flex flex-nowrap gap-8 items-center">
        <AvatarSingle
          :src="card.assessor_serializado?.foto"
          :alt="card.assessor_serializado?.nome"
        />

        <div class="flex flex-col gap-4 w-full">
          <div class="w-full flex items-center justify-between">
            <p class="text-neutral-60 text-paragraph-2">
              {{ card.assessor_serializado?.codigo }}
            </p>
            <q-space></q-space>
            <q-separator  :vertical="true"></q-separator>
            <span  :class="color" class="px-8 flex gap-0 items-center">
              <q-icon :class="color" class="!w-24 !h-24" :name="icon"></q-icon>
              {{ card.qtd_tempo_coluna }}</span
            >
          </div>
          <p
            :title="card.assessor_serializado?.nome"
            class="text-neutral-60 text-paragraph-2 !font-medium one-line"
          >
            {{ card.assessor_serializado?.nome }}
          </p>
        </div>
      </div>
    </section>

    <OButton
      v-if="type === 'board'"
      class="absolute bottom-4 right-4 !bg-neutral-10 !w-32 !h-32 opacity-0 group-hover:opacity-100 transition-opacity items-center !p-0 hover:!text-primary-pure"
      secondary
      @click.stop.prevent="menuRef.model = !menuRef.model"
    >
      <q-icon name="more_horiz"></q-icon>
    </OButton>

    <KanbanCardMenu
      v-if="type === 'board'"
      ref="menuRef"
      :data="card"
      @click:menu="(type) => emit('click:menu', card, type)"
    />
  </article>
</template>

<script setup>
import { computed, ref } from 'vue'
import AvatarSingle from 'components/Avatar/AvatarSingle.vue'
import GLOBAL from 'js/utils/GLOBAL'
import KanbanCardMenu from './KanbanCardMenu.vue'
import ObadgeTag from 'components/Badge/ObadgeTag.vue'
import OButton from 'components/Button/OButton.vue'

const { returnRGB } = GLOBAL
const props = defineProps({
  card: Object,

  type: {
    type: String,
    validator: (v) => ['board', 'especial'].includes(v),
  },
})

const icon = ref('')
const verifyColumn = (tempo, card) => {
  const tempoCard = card.qtd_tempo_coluna?.split(' ')
  if (tempoCard) {
    if (tempoCard[1] === 'horas' || tempoCard[1] === 'minutos') {
      icon.value = 'check_circle'
      return 'text-alert-success'
    }
    if (Number(tempoCard[0]) > tempo) {
      icon.value = 'error_outline'
      return 'text-alert-error'
    } else {
      icon.value = 'check_circle'
      return 'text-alert-success'
    }
  }
}

const color = computed(() => {
  switch (props.card.nome_coluna) {
    case 'Indicação':
      return verifyColumn(2, props.card)
    case 'Primeiro Contato':
      return verifyColumn(5, props.card)
    case 'Estudo Feito':
      return verifyColumn(7, props.card)
    case 'Em negociação':
      return verifyColumn(15, props.card)
    case 'Proposta Pendente':
      return verifyColumn(30, props.card)
  }
  return ''
})

const emit = defineEmits(['click', 'click:menu', 'click:voltar', 'teste'])
const menuRef = ref(null)

</script>

<style lang="sass">
.ghost
  position: relative
  &::after
    content: ""
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    display: block
    background: rgb(var(--neutral-40))
    border-radius: 3px
    overflow: hidden
</style>
