import { URLS } from "js/modules/axios"
import useApi from "composables/useApi"

export function tagsService(){
  const api = useApi()
  const getTagsTipoMesa = async (tipoMesa) => {
    try {
      const data = await api.query(URLS.tags, {mesa: tipoMesa})
      if (data.length > 0) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTags= async () => {
    try {
      const data = await api.query(URLS.tags)
    
        return data
      
    } catch (error) {
      console.log(error)
    }
  }
  return{getTagsTipoMesa, getTags}
}
