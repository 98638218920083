import { Dialog } from 'quasar'

import { inject, ref } from 'vue'
import { NotifySucess } from '../boot/Notify'

import { api } from 'modules/axios'
import axios from 'axios'
import Emitter from '../boot/Emitter'
import GLOBAL from 'js/utils/GLOBAL'
import ModalActionsConfirm from 'components/Modal/ModalActionsConfirm.vue'
import ModalConfirm from 'components/Modal/ModalConfirm.vue'

const { urls, data } = window.context
const bgTemplate = data?.profile?.background_kanban

// const imgUrl = bgTemplate ||  new URL('public/img/kanban_bg.png', import.meta.url).href
// const bgKanban = ref(imgUrl)

const observacao = ref('')
const produto = ref('')
const sellCross = ref(false)
const data_renegociacao = ref(null)
const comments = ref([])

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const csrfToken = getCookie('csrftoken');
const createNovaIndicacaoGanho = async(id, dados) => {
  try{
    const response = await axios.post(`${window.origin}/mesas/marcar_ganho_seguro/${id}/`, dados, {headers: {
      'X-CSRFToken': csrfToken
    }})
    console.log(response)
  }catch(error){
    console.log(error)
  }
}

const createNovaIndicacaoCrossSell = async(id, dados) => {
  try{
    const response = await axios.post(`${window.origin}/mesas/marcar_cross_sell_seguro/${id}/`, dados, {headers: {
      'X-CSRFToken': csrfToken
    }})
    console.log(response)
  }catch(error){
    console.log(error)
  }
}

async function setGanho(card, colGanho, url_mesa, key='') {
  const urlKey = key ? urls[key] : urls
  const url = url_mesa || urlKey.card(card.id)
  const  urlComentario =  urlKey.comentario(card.id)
  if (!colGanho) return
  Dialog.create({
    component: ModalActionsConfirm,
    componentProps: {
      title: 'Ganho',
      isGanho: true,
      text: 'Deseja confirmar o ganho?',
      persistent: true,
    },
  }).onOk(async () => {
    try {
      // adiconar aqui um if pra saber se tem cliente_criteria

      await api.patch(url, {
        coluna_kanban: colGanho,
      })
      if(sellCross.value){

      //  await api.post(urlComentario, {mensagem: observacao.value, mesa:card.id})
        await createNovaIndicacaoGanho(card.id, {produto: produto.value, observacao: observacao.value})

      }
      Emitter.emit('confirm:ganho', card, colGanho)
      Emitter.emit('atualiza:kanban')
     // Emitter.emit('update:comments')
      NotifySucess('Sucesso')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  })
}


async function setCrossSell(card, url_mesa, key='') {
  const urlKey = key ? urls[key] : urls
  const url = url_mesa || urlKey.card(card.id)
  const  urlComentario =  urlKey.comentario(card.id)
  // if (!colGanho) return
  Dialog.create({
    component: ModalActionsConfirm,
    componentProps: {
      title: 'Cross Sell',
    
      text: '',
      persistent: true,
    },
  }).onOk(async () => {
    try {
      // adiconar aqui um if pra saber se tem cliente_criteria

      // await api.patch(url, {
      //   coluna_kanban: colGanho,
      // })
      // if(sellCross.value){

        await api.post(urlComentario, {mensagem: observacao.value, mesa:card.id})
        await createNovaIndicacaoCrossSell(card.id, {produto: produto.value, observacao: observacao.value})

      // }
      Emitter.emit('atualiza:kanban')
      Emitter.emit('update:comments')
      NotifySucess('Sucesso')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  })
}

function setPerda(card, colPerda, url_mesa, key='') {
  if (!colPerda) return
  const urlKey = key ? urls[key] : urls
  const url = url_mesa || urlKey.card(card.id)
 const  urlComentario =  urlKey.comentario(card.id)
// console.log({urlComentario})
  Dialog.create({
    component: ModalActionsConfirm,
    componentProps: {
      title: 'Perda',
      isPerda: true,
      text: 'Deseja confirmar a perda?',
      persistent: true,
     
    },
  }).
  onOk(async () => {
    try {
      
      await api.patch(url, {
        coluna_kanban: colPerda,
        data_lembrete_renegociacao:data_renegociacao.value ? GLOBAL.FData(data_renegociacao.value, 'YYYY-MM-DD') : null
      })
      console.log({observacao})
      await api.post(urlComentario, {mensagem: observacao.value, mesa:card.id})
      await api.post(urlComentario, {mensagem: `Data de Renegociação: ${GLOBAL.FData(data_renegociacao.value, 'DD/MM/YYYY')}`, mesa:card.id})

      Emitter.emit('confirm:perda', card, colPerda)
      Emitter.emit('update:comments')
      NotifySucess('Sucesso')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  })
}

function setArquivar(card, colArquivar, url_mesa, key='') {
  if (!colArquivar) return
  const urlKey = key ? urls[key] : urls
  const url = url_mesa || urlKey.card(card.id)
  const  urlComentario =  urlKey.comentario(card.id)
  Dialog.create({
    component: ModalActionsConfirm,
    componentProps: {
      title: 'Arquivar',
      isArquivado:true,
      text: 'Deseja realmente arquivar?',
      persistent: true,
    },
  }).onOk(async () => {
    try {
      await api.patch(url, {
        coluna_kanban: colArquivar,
        data_lembrete_renegociacao:data_renegociacao.value ? GLOBAL.FData(data_renegociacao.value, 'YYYY-MM-DD') : null
      })
      await api.post(urlComentario, {mensagem: observacao.value, mesa:card.id})
      await api.post(urlComentario, {mensagem: `Data de Renegociação: ${GLOBAL.FData(data_renegociacao.value, 'DD/MM/YYYY')}`, mesa:card.id})
      Emitter.emit('confirm:arquivar', card, colArquivar)
      Emitter.emit('update:comments')
      NotifySucess('Arquivado')

      return true
    } catch (error) {
      console.log(error)
      return false
    }
  })
}

function setDesarquivar(card, col, url_mesa, key) {
  if (!col) return
  const urlKey = key ? urls[key] : urls
  const url = url_mesa || urlKey.card(card.id)
 
  Dialog.create({
    component: ModalConfirm,
    componentProps: {
      title: 'Desarquivar',
      text: 'Deseja realmente desarquivar?',
      persistent: true,
    },
  }).onOk(async () => {
    try {
      await api.patch(url, {
        coluna_kanban: col,
      })

      Emitter.emit('confirm:desarquivar', card, col)
      Emitter.emit('voltar:card', card)
      NotifySucess('Desarquivado')
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  })
}

export { setGanho, setPerda, setArquivar, setDesarquivar, setCrossSell, observacao,data_renegociacao, sellCross, produto, comments }
