<template>
  <q-item
    v-ripple
    clickable
    class="flex items-center gap-12 pl-12 rounded-md select-none"
    :title="file.nome"
    tag="a"
    download
    target="_blank"
    :href="file.url"
  >
    <div class="bg-neutral-10 w-48 h-48 rounded flex items-center justify-center">
      <q-icon size="1.5rem" :name="iconName" />
    </div>
    <div class="flex flex-col gap-2">
      <p class="text-paragraph-2 text-black one-line">
        {{ file.nome }}
      </p>
      <p class="text-paragraph-3 text-neutral-60">
        {{ file.extensao?.replace('.', '') }}
        {{ file.tamanho && `⠂ ${file.tamanho}` }}
      </p>
    </div>
    <q-space />
    <q-icon name="download" size="2rem" />
  </q-item>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  file: Object,
})

const types = {
  doc: 'svguse:#icon_file_doc',
}

const iconName = computed(() => types.doc)
</script>

<style lang="scss" scoped></style>
