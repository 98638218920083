import { URLS } from 'modules/axios'
import useApi from 'composables/useApi'

export function assessorService() {
  const api = useApi()

  const getAssessores = async () => {
    try {
      const data = await api.query(URLS.assessores)
      
      return data.results || []
    } catch (error) {
      console.log(error)
    }
  }

  async function getAssessorLogado() {
    try {
      const data = await api.query(URLS?.assessor_logado)
      if (data) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getNivelAssessorLogado() {
    try {
      const data = await api.query(URLS?.nivel_assessor)
      if (data) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getCaptacaoAssessor() {
    try {
      const data = await api.query(URLS?.captacao_assessor)
      if (data) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getReceitaAssessor = async () => {
    try {
      const data = await api.query(URLS?.receita_assessor)
      if (data) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSaldoAssessor = async () => {
    try {
      const data = await api.query(URLS?.saldo_assessor)
      if (data) {
        return data?.pontuacao
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProgressoAssessor = async () => {
    try {
      const data = await api.query(URLS?.progresso_nivel_assessor)
      if (data) {
        return data?.results
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMedalhasAssessor = async () => {
    try {
      const data = await api.query(URLS?.medalhas_assessor)
      if (data) {
        return data?.results
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getAssessorLogado,
    getNivelAssessorLogado,
    getCaptacaoAssessor,
    getReceitaAssessor,
    getSaldoAssessor,
    getProgressoAssessor,
    getMedalhasAssessor,
    getAssessores
  }
}
