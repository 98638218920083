<template>
  <div
    v-if="!isLoading"
    class="flex flex-col gap-32 mb-48 justify-center w-full items-center"
  >
    <SecaoIntroducao
      class="w-full min-h-[25rem] bg-neutral-80 flex items-center"
      @click:openModalCreateIndicacao="openCreateIndicacao"
    />

    <SecaoSeguroVida
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoSeguroSaude
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoConsorcio
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoFinanciamentoImobiliario
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoHomeEquity
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoSegurosMassificados
      class="w-[90%] mx-auto max-w-[1300px]"
      @click:openModalCreateIndicacao="openCreateIndicacao"
      @click:download="downloadArquivo"
    />
  </div>
  <ModalKanbanIndicacaoCreate />
</template>

<script setup>
import { api } from '../../modules/axios'
import { computed, inject, onBeforeMount } from 'vue'
import { NotifyAlert } from '../boot/Notify'
import GLOBAL from '../../utils/GLOBAL'
import ModalKanbanIndicacaoCreate from '../components/Modal/ModalKanbanIndicacaoCreate.vue'
import SecaoConsorcio from '../components/LandingPage/SecaoConsorcio.vue'
import SecaoFinanciamentoImobiliario from '../components/LandingPage/SecaoFinanciamentoImobiliario.vue'
import SecaoHomeEquity from '../components/LandingPage/SecaoHomeEquity.vue'
import SecaoIntroducao from '../components/LandingPage/SecaoIntroducao.vue'
import SecaoSeguroSaude from '../components/LandingPage/SecaoSeguroSaude.vue'
import SecaoSegurosMassificados from '../components/LandingPage/SecaoSegurosMassificados.vue'
import SecaoSeguroVida from '../components/LandingPage/SecaoSeguroVida.vue'

const { parceiro, data } = inject('context')
const { modalKanbanIndicacaoCreate } = inject('modal')
const isUserLogado = computed(() => data?.id_user && data?.id_user !== 'None')
const setColorsParceiroId = (parceiroID) => {
  const rgbCor = GLOBAL.returnRGB(parceiroID.cor1)
  const rgbCor2 = GLOBAL.returnRGB(parceiroID.cor2)
  document.documentElement.style.setProperty('--primary-pure', rgbCor)
  document.documentElement.style.setProperty('--branding-pure', rgbCor)
  document.documentElement.style.setProperty('--branding-light', rgbCor)
  document.documentElement.style.setProperty('--secondary', rgbCor2)
}

const openCreateIndicacao = () => {
  if (isUserLogado.value) {
    modalKanbanIndicacaoCreate.open()
  } else {
    window.location.href = `${window.location.origin}/login/`
  }
}

const downloadArquivo = async (id) => {
  console.log({ id })
  try {
    if (!id) {
      NotifyAlert('Arquivo não disponível para o download')
      return
    }
    const response = await api.get(`/documento/${id}`, {
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))

    const link = document.createElement('a')
    link.href = url

    const contentDisposition = response.headers['content-disposition']
    let fileName = 'arquivo-desconhecido'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
    }

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    link.remove()
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error)
  }
}

onBeforeMount(async () => {
  setColorsParceiroId(parceiro)
})
</script>

<style lang="sass" scoped></style>
