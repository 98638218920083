<template>
  <q-card class="min-w-[400px] min-h-[300px] flex flex-col gap-16 py-16">
    <h1
      class="px-16 text-primary-pure text-title-1 !font-semibold !text-[24px] !leading-[160%] !tracking-[-0.48px]"
    >
      Notificações
    </h1>

    <div class="flex gap-8 p-16 items-center w-full">
      <OButton
        :class="
          active === 'todas'
            ? '!bg-primary-pure !text-white'
            : 'text-primary-pure'
        "
        size="md"
        tertiary
        class="dark:text-primary-pure"
        @click="atualizaNotificacoes('todas')"
        >Todas</OButton
      >
      <OButton
        :class="
          active === 'lida'
            ? '!bg-primary-pure !text-white'
            : 'text-primary-pure'
        "
        size="md"
        tertiary
        class="dark:text-primary-pure border-primary-pure"
        @click="atualizaNotificacoes('lida')"
        >Visualizadas</OButton
      >
      <OButton
        :class="
          active === 'nao_visualizadas'
            ? '!bg-primary-pure !text-white'
            : 'text-primary-pure'
        "
        size="md"
        class="dark:text-primary-pure border-primary-pure"
        tertiary
        @click="atualizaNotificacoes('nao_visualizadas')"
        >Não Visualizadas</OButton
      >
    </div>

    <div class="flex-col gap-0 max-h-[400px] overflow-y-auto !flex-nowrap">
      <template v-if="!isLoading && notificacoes.length > 0">
        <CardNotificacaoItem
          v-for="notificacao in notificacoes"
          :key="notificacao.id"
          :notificacao="notificacao"
        ></CardNotificacaoItem>
      </template>
      <EmptyItem v-else-if="!isLoading" text="Sem Notificações" />
    </div>
    <q-inner-loading v-if="isLoading" :showing="true" class="mt-40">
      <q-spinner size="50px" color="primary" />
    </q-inner-loading>
  </q-card>
</template>

<script setup>
import { notificacaoService } from '../../services/notificacao.service'
import { onMounted, ref } from 'vue'
import CardNotificacaoItem from '../Card/CardNotificacaoItem.vue'
import Emitter from '../../boot/Emitter'
import EmptyItem from 'components/Empty/EmptyItem.vue'
import OButton from 'components/Button/OButton.vue'

const { getNotificacoes, isLoading } = notificacaoService()
const active = ref('todas')
const notificacoes = ref([])

const setNotificacoes = async () => {
  if (active.value === 'todas') {
    notificacoes.value = await getNotificacoes()
  } else {
    notificacoes.value = await getNotificacoes({
      lida: active.value === 'lida' ? 'True' : 'False',
    })
  }
}

const atualizaNotificacoes = async (valueActive) => {
  active.value = valueActive
  await setNotificacoes()
}

onMounted(async () => {
  notificacoes.value = await getNotificacoes()
})

Emitter.on('set:notificacoes', async () => {
  await setNotificacoes(active.value)
})
</script>

<style lang="scss" scoped></style>
