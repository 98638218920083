<template>
  <q-card
    ref="cardChatBox"
    class="q-chat py-16 flex flex-col bg-white dark:bg-d-neutral-10 flex-1 flex-nowrap h-full md:p-8 relative justify-end"
  >
    <OButton
      v-show="scroll < 0.88 && scroll !== false"
      secondary
      round
      icon="arrow_forward"
      class="rotate-90 !p-0 !h-32 !w-32 !min-w-0 !min-h-0 bg-neutral-40 hover:!bg-primary-pure hover:!text-white absolute bottom-120 right-16 z-50"
      @click="scrollChatToBottom(true)"
    />

    <div class="px-16 flex flex-col">
      <TextIcon
        class="text-title-4"
        icon="svguse:#icon_chat"
        :icon-props="{ class: 'text-primary-pure/40' }"
        text="Acompanhamentos"
      />
    </div>

    <SkeletonChat v-if="isLoading" class="" />

    <div
      v-else-if="comentariosResult?.length === 0 && !isLoading"
      class="flex place-content-center h-full flex-[1_1_auto] px-16"
    >
      <div class="flex flex-col gap-6 opacity-40">
        <q-icon class="block mx-auto" name="fluorescent" size="2.5rem"></q-icon>
        <p>Sem mensagens no momento</p>
      </div>
    </div>

    <span
      v-else-if="comentariosResult?.length && !isLoading"
      class="text-neutral-60 text-paragraph-2 mb-8 px-16"
      >Você tem <strong>{{ comentariosResult?.length }}</strong> acompanhamentos
      registrados.</span
    >
    <!--     anexo_comentario -->

    <q-scroll-area
      v-if="comentariosResult?.length && !isLoading"
      class="flex flex-col gap-8 flex-1 pr-10 px-16"
      @scroll="handleScroll"
    >
      <OChatMessage
        v-for="data in comentariosResult"
        :key="data.id"
        :data="data"
      />

      <slot name="top"></slot>
    </q-scroll-area>

    <footer v-if="showInput" class="pt-16">

      <div v-if="isLoading" class="flex gap-8 mt-16 px-16 flex-nowrap">
        <q-skeleton height="40px" width="100%" />
        <q-skeleton height="40px" width="140px" />
      </div>

      <div v-else class="flex gap-8 px-16">
        <OButton
          icon="attach_file"
          size="md"
          secondary
          class="!h-[2.75rem] !w-[2.75rem] !p-0 !border-neutral-40"
          :loading="loading.anexo"
          @click="submitAnexo"
        />
        <OInput
          v-model="message"
          size="md"
          placeholder="Escreva sua mensagem"
          class="h-[2.75rem] flex-1 bg-white dark:!bg-transparent no-label"
          autofocus
          @keydown.enter.prevent="submitMessage"
        ></OInput>

        <OButton
          secondary
          class="bg-white h-[2.75rem] dark:text-white !min-w-[100px]"
          :loading="loading.button"
          @click="submitMessage"
        >
          Enviar
        </OButton>
      </div>
    </footer>
  </q-card>
</template>

<script setup>
import { acompanhamentoSeguroService } from '../../services/acompanhamento_seguro.service'
import { Dialog, scroll as qScroll } from 'quasar'
import { nextTick, onMounted, onUnmounted, ref, unref, watch } from 'vue'
import { NotifyAlert, NotifyError } from 'js/vue/boot/Notify'
import ModalAnexo from 'components/Modal/ModalAnexo.vue'
import OButton from 'components/Button/OButton.vue'
import OChatMessage from 'components/Chat/OChatMessage.vue'
import OInput from 'components/Input/OInput.vue'
import SkeletonChat from 'components/Skeleton/SkeletonChat.vue'
import TextIcon from 'components/Text/TextIcon.vue'

// const { getScrollHeight, getVerticalScrollPosition, getScrollTarget } = scroll

// const { URLS } = api.defaults

const cardChatBox = ref(null)

const props = defineProps({
  comments: Object,
  isLoading: Boolean, 
  sendAnexo: Function,
  closeModal: Function,
  filters: String,
  mesa: Number || String,
  mesaHub: Number || String,
  canUpdate: {
    type: Boolean,
    default: true,
  },
  showInput: {
    type: Boolean,
    default: true,
  },
  tipo: {
    type: String,
    default: 'task',
  },
  cronogramaAtivo: {
    type: Object,
    default: () => ({}),
  },
})

const scroll = ref(false)
const comentariosResult = ref(unref(props.comments))
const {getComments, sendComment} = acompanhamentoSeguroService()
watch(
  () => props.comments,
  async (v) => {
    comentariosResult.value = unref(v)
    chatContainer = cardChatBox.value.$el.querySelector(
      '.q-scrollarea__container'
    )
    await nextTick()
    scrollChatToBottom()
  },
  { deep: true, flush: 'post' }
)

const message = ref('')
const data_entrega = ref('')
let chatContainer

function scrollChatToBottom(animate) {
  const container = (chatContainer = cardChatBox.value.$el.querySelector(
    '.q-scrollarea__container'
  ))
  if (animate)
    qScroll.setVerticalScrollPosition(container, container.scrollHeight, 300)
  else container?.scrollTo(0, container.scrollHeight)
}
function handleScroll(v) {
  if (v.verticalPercentage) scroll.value = v.verticalPercentage
}
async function submitMessage() {
  const mensagem = {
    txt: message.value,
    tipo_etapa: tipoetapa.value || '',
    data_entrega: data_entrega.value,
    cronograma: props.cronogramaAtivo,
  }

  if (!mensagem.txt) {
    NotifyAlert('Digite uma mensagem para enviar')
    return
  }

  message.value = ''
  data_entrega.value = ''
  loading.value.button = true
  
  await sendComment({mensagem: mensagem.txt, mesa:props.mesa})
  await getComments(props.mesaHub)
  loading.value.button = false

  scrollChatToBottom()
}

let timeout

async function updateChatInterval(container) {
  clearTimeout(timeout)
  chatContainer = cardChatBox.value.$el.querySelector(
    '.q-scrollarea__container'
  )

  if (props.canUpdate && props.getComments) {
    
    const newComments = await getComments(props.mesaHub)
    const newC = newComments.map((i) => i.mensagem)
    const oldC = comentariosResult.value?.map((i) => i.mensagem)
    if (newC?.toString() === oldC?.toString()) return
    newComments?.length && scrollChatToBottom()
  }

  timeout = setTimeout(() => updateChatInterval(container), 15000)
}

const tipoetapa = ref(null)


const loading = ref({
  button: false,
  anexo: false,
})

const file = ref({})

function submitAnexo() {
  Dialog.create({
    component: ModalAnexo,
    componentProps: {
      title: 'Anexar',
      file: file.value,
      persistent: false,
    },
  }).onOk(async (file) => {
    try {

      loading.value.anexo = true
      await props.sendAnexo(file)
      await getComments(props.mesaHub, props.filters)
    } catch (error) {
      console.log(error)
      NotifyError('Erro ao enviar anexo')
      return false
    } finally {
      loading.value.anexo = false
    }
  })
}

onMounted(() => {
  chatContainer = cardChatBox.value.$el.querySelector(
    '.q-scrollarea__container'
  )

  updateChatInterval(chatContainer)
  scrollChatToBottom()

 
})

onUnmounted(() => {
  clearTimeout(timeout)
})
defineExpose({ scrollChatToBottom })
</script>

<style lang="sass" scoped>
.q-chat
  &:deep(.q-scrollarea__content)
    display: flex
    flex-direction: column !important
    justify-content: flex-end

  &:deep(.q-field__label)
    background: transparent !important
</style>
