<template>
  <div
    class="kanban-col border-neutral-40 border rounded-md"
    :data-id="col.id"
    :data-nome="col.nome"
  >
    <header
      class="px-6 pb-14 pt-6 w-full flex items-center flex-nowrap gap-8 md:p-8 bg-white rounded-t-md"
    >
      <div class="inline-flex items-center gap-6 w-full mt-6">
        <p class="text-headline-3 one-line md:!text-headline-4 !font-semibold">
          {{ col.nome }}
        </p>

        <q-space />
        <q-spinner
          v-if="loading.cards[col.nome]"
          class="ml-auto w-24 h-24"
          color="primary"
          size="1rem"
          :thickness="4"
        />
        <BaseBadge
          v-else
          class="!text-sm !font-medium !rounded !w-28 !p-0 !h-24 dark:!bg-white/10 dark:!text-white/70 bg-primary-pure/10 !text-primary-pure md:!text-12"
        >
          {{ length || 0 }}
        </BaseBadge>
      </div>

     
    </header>

    <div
      v-show="!loading.cards[col.nome]"
      class="cards-wrapper swiper-no-swiping !p-8 !pt-12"
    >
      <q-scroll-area>
        <slot></slot>
      </q-scroll-area>
    </div>
  </div>
</template>

<script setup>
import {  inject, } from 'vue'

import BaseBadge from 'components/Badge/BaseBadge.vue'

const emit = defineEmits(['update:cards'])

const props = defineProps({
  length: Number,
  col: Object,
})

const loading = inject('loading')



</script>

<style lang="sass" scoped>
@import 'css/responsivo.sass'


//animacao
.flip-list-move
  transition: transform 0.5s

.no-move
  transition: transform 0s

.cards-wrapper :deep(.transition-div)
  padding-top: 0 !important


.kanban-col
  --kanban-col-bg: rgba(var(--neutral-20), 1)
  --kanban-col-width: 18rem
  background: var(--kanban-col-bg)
  width: var(--kanban-col-width)
  max-height: 100%
  min-height: 330px
  height: max-content
  border-radius: 6px
  display: flex
  flex-direction: column
  flex-shrink: 0
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px
  .cards-wrapper
    display: flex
    flex-direction: column
    flex: initial
    height: 100%
    min-height: 270px

  :deep(.transition-div)
    display: flex
    flex-direction: column
    gap: 8px
    flex: 1
    height: 100%
    min-height: 240px
    margin-bottom: .5rem

  //qscrollbar dentro da coluna
  :deep(.q-scrollarea)
      display: flex
      flex-direction: column
      height: 100%
      flex: 1


  :deep(.q-scrollarea__container)
    display: flex
    flex-direction: column
    height: 100%
    flex: 1


  :deep(.q-scrollarea__bar--v, .q-scrollarea__thumb--v)
    right: 0
    width: 6px
</style>
