<template>
  <q-tabs
    v-model="tabs"
    class="bg-transparent opacity-100 !font-semibold w-max mx-auto md:mx-0 md:items-start"
    active-color="primary-pure"
  >
    <q-tab
      class="q-tab-kanban"
      name="board"
      @click.prevent.stop="
        navigateQuadro ? navigateTab() : null
      "
    >
      <template
        class="inline-flex flex-row !opacity-100 items-center gap-4 !text-paragraph-1 !text-primary-pure !font-medium !h-full !rounded-none"
      >
        <q-icon
          :size="Screen.lt.md ? '1.375rem' : '1.5rem'"
          name="view_kanban"
        ></q-icon>
        <p class="text-paragraph-1 text-primary-pure md:!text-14">Quadro</p>
      </template>
    </q-tab>

    <q-tab
      name="list"
      class="q-tab-kanban"
      @click.prevent.stop="
        navigateList ? navigateTab() : null
      "
    >
      <template
        class="inline-flex flex-row !opacity-100 items-center gap-4 !text-paragraph-1 !text-primary-pure !font-medium !h-full !rounded-none"
      >
        <q-icon
          :size="Screen.lt.md ? '1.375rem' : '1.5rem'"
          name="svguse:#icon_table_list"
        ></q-icon>
        <p class="text-paragraph-1 md:!text-14">Lista</p>
      </template>
    </q-tab>
  
  </q-tabs>
</template>

<script setup>
import {  inject } from 'vue'
import { Screen } from 'quasar'
import {  useRouter } from 'vue-router';

const { navigateList, navigateQuadro, navigateDashboard, data } = defineProps({
  navigateList: { type: Boolean, default: false },
  navigateDashboard: { type: Boolean, default: false },
  navigateQuadro: { type: Boolean, default: false },
  data: { type: Object, default: () => ({}) },
})
const tabs = inject('tabs')
const router = useRouter()
function navigateTab() {
  router.push({name: 'kanban', query: {tab: tabs.value}})
}
</script>

<style lang="sass" scoped>

.q-tab-kanban
  opacity: 1 !important
</style>
