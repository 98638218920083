<template>
  <div class="w-full shrink-0 md:-mt-4 px-24 py-16 max-h-[500px]">
    <TextIcon
      class="text-title-4 pt-16 pb-16"
      icon="history"
      text="Histórico"
      :icon-props="{ class: 'text-primary-pure/40' }"
    />

    <div
      class="flex flex-col gap-16 w-full virtual-scroll flex-1 overflow-auto pb-16"
    >
      <template v-if="!isLoading">
        <div
          v-for="item in historico"
          :key="item.id"
          class="flex items-start gap-4 flex-nowrap md:gap-6"
        >
          <AvatarSingle :src="item?.dados_usuario?.foto"></AvatarSingle>

          <div class="flex flex-col">
            <div
              class="flex gap-4 text-paragraph-2 md:flex-col md:gap-0 md:!text-12"
            >
              <p class="font-medium capitalize">
                {{ item?.dados_usuario?.nome }}
              </p>
              <div class="opacity-70">
                {{ item?.historico }}
              </div>
            </div>

            <p class="opacity-70 text-paragraph-3 md:!text-paragraph-4">
              {{ FDataAndTime(item?.data_criacao) }}
            </p>
          </div>
        </div>
      </template>
      <EmptyItem v-if="!isLoading && historico?.length === 0 " text="Histórico vazio"  />
      <q-inner-loading v-if="isLoading" :showing="true">
        <q-spinner size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </div>
</template>

<script setup>
import { historicoService } from '../../services/historico.service'
import {  onMounted, ref } from 'vue'
import AvatarSingle from 'components/Avatar/AvatarSingle.vue'
import EmptyItem from '../Empty/EmptyItem.vue'
import GLOBAL from 'js/utils/GLOBAL'
import TextIcon from 'components/Text/TextIcon.vue'

const { FDataAndTime } = GLOBAL
const props = defineProps({
  data: Object,
})
const historico = ref([])

const {getHistorico, isLoading} = historicoService()

onMounted(async() => {
 historico.value = await getHistorico(props.data.id)
})
</script>

<style lang="scss" scoped></style>
