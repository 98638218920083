import { NotifyAlert } from "../boot/Notify";
import { URLS } from "../../modules/axios";
import useApi from "../composables/useApi";

export function mesaSeguroService() {
  const api = useApi()

  
async function getDadosMesaSeguroId(id) {
  
  try {
    const responseCardSeguro = await api.query(URLS.card_seguro + `${id}/`)
    
   return responseCardSeguro
  } catch (error) {
    console.log(error)
    NotifyAlert('Um erro ocorreu')
    
  }
}
  
   
async function postIndicacaoMesaSeguro(dados) {
  
  try {
    const responseCardSeguro = await api.mutation(URLS.indicacao_seguro,'POST', dados )
    
   return responseCardSeguro
  } catch (error) {
    console.log(error)
    NotifyAlert('Um erro ocorreu')
    
  }
}
  
  return {getDadosMesaSeguroId, postIndicacaoMesaSeguro, ...api}
}
