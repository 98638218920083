import { URLS } from "js/modules/axios"
import useApi from "composables/useApi"

export function historicoService(){
  const api = useApi()
  const getHistorico = async (idMesa) => {
    try {
      const {results} = await api.query(URLS.historico_seguro, { mesa_seguro: idMesa })
      return results ?? []
    } catch (error) {
      
      console.log(error)
    }
  }


  return{getHistorico, ...api}
}
