<template>
  <div
    class="text-paragraph-2 text-center m-auto !h-full flex-1 flex flex-col justify-center"
  >
    <q-icon
      class="block mx-auto opacity-30"
      :name="icons.at(getRandomIndex(icons))"
      size="2.5rem"
      :class="classP"
    ></q-icon>
    <p class="opacity-40" :class="classP">{{ text }}</p>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  classP: String,
  icons: Array
})

const icons = props.icons || ['upcoming']

function getRandomIndex(array) {
  const randomIndex = Math.floor(Math.random() * array.length)
  return randomIndex
}
</script>

<style lang="scss" scoped></style>
