<template>
  <div v-if="!loading" class="flex flex-col">
    <div class="flex gap-4 mb-6 items-center">
      <q-icon
        size="1.25rem"
        class="shrink-0 text-primary-pure opacity-40"
        name="svguse:#icon_funil"
      />
      <p class="text-paragraph-2 text-neutral-70 !font-medium">
        Funil e etapa de vendas  ⠂ {{ list.findLast(i => i.active)?.name}}
      </p>
    </div>
    <div class="flex gap-2 items-center">
      <template v-for="(item, index) in list" :key="index">
        <q-icon
          size=".75rem"
          class="shrink-0 text-primary-pure/10"
          :class="{ '!text-primary-pure': item.active }"
          name="svguse:#icon_circle_funil"
        >
        </q-icon>
        <div
          class="w-auto flex flex-1 h-6 rounded-lg bg-primary-pure/10"
          :class="{ '!bg-primary-pure': item.active }"
        >
          <q-tooltip>
            {{ item.name }}
          </q-tooltip>
        </div>

        <q-icon
          v-if="list.length === index + 1"
          size=".75rem"
          class="shrink-0 text-primary-pure/10"
          :class="{ '!text-primary-pure': item.active }"
          name="svguse:#icon_circle_funil"
        />
      </template>
    </div>
  </div>
  <div v-else class="flex flex-col gap-8 w-full">
    <q-skeleton v-once width="160px" height="20px"></q-skeleton>
    <q-skeleton v-once width="100%" height="20px"></q-skeleton>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  loading: { type: Boolean, default: true },
  cols: Array,
  active: Number,
})

const indexActive = computed(() =>
  props.cols.map((i) => i.id).indexOf(props.active)
)

const list = computed(() => {
  return props.cols?.reduce((acc, item, index) => {
    const active = index <= indexActive.value
    const obj = {
      type: 'dash',
      active,
      name: item.nome,
    }
    acc.push(obj)
    return acc
  }, [])
})

</script>

<style lang="scss" scoped></style>
