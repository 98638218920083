<template>
  <div class="flex items-center gap-6">
    <div class="rounded-full">
      <q-avatar
        size="2rem"
        font-size="0.875rem"
        class="shrink-0"
        v-bind="attrs"
      >
        <img class="" :src="src" :alt="name || alt" />
      </q-avatar>
    </div>
    <div class="flex flex-col">
      <p 
        v-if="title" 
        class="text-headline-4 text-neutral-70">
        {{ title }}
      </p>
      <p v-if="name">{{ name }}</p>
    </div>
  </div>
</template>

<script setup>
import { useAttrs } from 'vue'

const attrs = useAttrs()
defineProps({
  src: String,
  name: { type: String, default: '' },
  title: { type: String, default: '' },
  alt: { type: String, default: '' },
})
</script>

<style lang="sass" scoped>
.avatar.ajuste-img :deep(img)
  width: calc(100% - 3px)
  height: calc(100% - 3px)
</style>
