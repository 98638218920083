<template>
  <q-table ref="componentRef" v-bind="attrs" class="OTable">
    <template v-for="slot in Object.keys(slots)" #[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps"></slot>
    </template>
  </q-table>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { onMounted, ref, useAttrs, useSlots } from 'vue'
const slots = useSlots()
const attrs = useAttrs()
const componentRef = ref(null)

defineExpose({ componentRef })
</script>

<style lang="scss">
.q-table__bottom--nodata {
	justify-content: center;
	align-items: center;
	min-height: 100px;
	color: rgba(var(--neutral-60),1);
	font-size: 1rem;
}
</style>
