<template>
  <div class="flex h-full flex-1 px-16">
    <div class="flex flex-col mt-auto mb-24 w-full gap-8">
      <div class="flex flex-col">
        <q-skeleton height="32px" width="220px" class="ml-auto" />
        <q-skeleton height="12px" width="120px" class="ml-auto mt-4" />
      </div>
      <div class="flex flex-col">
        <q-skeleton height="32px" width="220px" class="" />
        <q-skeleton height="12px" width="120px" class="mt-4" />
      </div>

      <div class="flex flex-col">
        <q-skeleton height="32px" width="220px" class="ml-auto" />
        <q-skeleton height="12px" width="120px" class="ml-auto mt-4" />
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
