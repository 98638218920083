<template>
  <div
    v-if="mesa.isCambio || mesa.isDigital || mesa.isAdvisory"
    class="flex gap-16 mt-16"
  >
    <KanbanModalFieldset
      label="Breve descritivo sobre a(o) operação/cliente"
      :text="data.observacoes ?? data.resumo"
    />
  </div>

  <div v-else class="flex gap-16 mt-16">
    <KanbanModalFieldset
      label="Observações"
      :text="data.observacoes ?? data.resumo ?? data.observacao"
      data-key="observacoes"
      :debounce-time="2600"
      :input-props="{
        type: 'textarea',
      }"
      @patch:mesa="() => onEmit()"
    />
  </div>
</template>

<script setup>
import KanbanModalFieldset from './KanbanModalFieldset.vue'

const { mesa, data } = defineProps({
  mesa: { type: Object, default: () => ({}) },
  data: { type: Object, default: () => ({}) },
})

const emit = defineEmits(['patch:mesa:observacao'])

function onEmit() {
  emit('patch:mesa:observacao')
}
</script>

<style lang="scss" scoped></style>
