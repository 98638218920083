import { createRouter, createWebHistory } from 'vue-router'
import PageHome from 'pages/PageHome.vue'
import PageKanban from '../pages/PageKanban.vue'
import PageLanding from '../pages/PageLanding.vue'
// import PageSVGViewer from '../pages/PageSVGViewer.vue'


const routes = [
 
  {
    path: '/',
    name: 'landing_page',
    component: PageLanding
  },
  {
    path: '/kanban_mesa_seguro/',
    name: 'kanban',
    component: PageKanban
  },
 
  // {
  //   path: '/svg_viewer/',
  //   name: 'svg_viewer',
  //   component: PageSVGViewer
  // }
]

// window.menuList =  routes.map(item =>({name: item.name, slug: item.path, level:1}))
   

const Router = createRouter({
  history: createWebHistory(),
  routes,
})


export default Router
