<template>
  <div class="kanban-container ">
    <BaseSubHeader ref="baseSubHeaderRef" :data="data" :mesa="mesa">
      <template #right-0>
        <OSelect
          v-model="produto"
          class="!max-w-[250px]"
          use-input
          :options="options.produtos"
          option-label="label"
          option-value="value"
          map-options
          emit-value
          multiple
          size="md"
          clearable
          label="Produto"
          :loading="isLoadingCards"
          :disable="isLoadingCards"
          @clear="produto = []"
        />
      </template>

      <template #right-1>
        <OtabsKanban :data="data" :navigate-dashboard="true" />

        <q-space />

        <!-- <OInput
          v-model="searchModel"
          size="md"
          label="Pesquisar"
          class="label-transparent min-w-[200px]"
          clearable
        /> -->
      </template>
      <template #right-2>
        <OButton size="md" primary @click="modalKanbanIndicacaoCreate.open()">
          <q-icon name="svguse:#icon_operacao"></q-icon>
          Operação

          <q-tooltip
            anchor="top middle"
            self="center middle"
            class="whitespace-nowrap"
          >
            Criar nova operaçao
          </q-tooltip>
        </OButton>

        <!-- <OButton
               size="md"
               primary
               :href="exportSeguro"
             >
               Exportar
     
               <q-tooltip
                 anchor="top middle"
                 self="center middle"
                 class="whitespace-nowrap"
               >
                 Exportar Seguro
               </q-tooltip>
             </OButton> -->

        <OButton class="!p-0 !w-40 !h-40" size="md" secondary>
          <q-icon size="1.25rem" class="shrink-0" name="more_horiz"></q-icon>
          <q-menu>
            <q-list class="min-w-[220px]" padding>
              <q-item
                v-close-popup
                clickable
                class="items-center px-16 py-8 font-medium gap-8"
                @click="modalKanbanSide.open()"
              >
                <q-icon name="svguse:#icon_three_points" size="1.5rem"></q-icon>
                <p class="whitespace-nowrap">Ganhos / Perdidos / Arquivados</p>
              </q-item>
              <!-- <q-separator />
              <q-item
                v-close-popup
                clickable
                class="items-center px-16 py-8 font-medium gap-8"
                @click="onBgChange"
              >
                <q-icon name="image" size="1.5rem" />
                Alterar Plano De Fundo
              </q-item>
              <q-separator />
              <q-item
                v-close-popup
                clickable
                class="items-center px-16 py-8 font-medium gap-8"
                @click="onBgRemove"
              >
                <q-icon name="remove_circle_outline" size="1.5rem" />
                Remover Plano De Fundo
              </q-item> -->
            </q-list>
          </q-menu>
        </OButton>
      </template>
    </BaseSubHeader>
    <section
      v-if="tabs === 'board'"
      ref="wrapper"
      class="flex gap-16 px-24 kanban-col--wrapper"
    >
      <KanbanCol
        v-for="col in listaCards.sort(ordenateKey('ordem'))"
        :key="col.id"
        :length="col.cards.length"
        :col="col"
      >
        <draggable
          v-model="col.cards"
          :disabled="true"
          class="flex flex-col gap-8"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
            class: `transition-div `,
          }"
          group="a"
          item-key="name"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ element }">
            <KanbanCard
              type="board"
              :card="element"
              @click="() => onOpenModal(element)"
              @click:menu="(element, type) => onClickMenu(element, type)"
            />
          </template>
        </draggable>
      </KanbanCol>
    </section>
    <section v-show="tabs === 'list' && !loading.cols" class="h-[inherit] kanban-list ">
      <KanbanList @openModal="(card) => onOpenModal(card)" />
    </section>
  </div>
  <ModalKanban :cols="cols" />
  <ModalKanbanSide
    @click:openModal="(card) => onOpenModal(card)"
    @click:voltar="(card) => onSendCardToBoard(card)"
  />
  <ModalKanbanIndicacaoCreate />
</template>

<script setup>
import { colors, Dialog } from 'quasar'
import { computed, inject, onBeforeMount, onMounted, provide, ref, watch } from 'vue'
import { NotifyError } from '../boot/Notify'
import { profile } from '../store/profile.store'
import { profileService } from '../services/profile.service'
import { useRoute, useRouter } from 'vue-router'
import BaseSubHeader from '../components/Header/BaseSubHeader.vue'
import Draggable from 'vuedraggable'
import Emitter from '../boot/Emitter'
import GLOBAL from '../../utils/GLOBAL'
import KanbanCard from '../components/Kanban/KanbanCard.vue'
import KanbanCol from '../components/Kanban/KanbanCol.vue'
import KanbanList from './KanbanList.vue'
import ModalAnexo from '../components/Modal/ModalAnexo.vue'
import ModalConfirm from 'components/Modal/ModalConfirm.vue'
import ModalKanban from 'components/Modal/ModalKanban.vue'
import ModalKanbanIndicacaoCreate from '../components/Modal/ModalKanbanIndicacaoCreate.vue'
import ModalKanbanSide from 'components/Modal/ModalKanbanSide.vue'
import OButton from '../components/Button/OButton.vue'
import OInput from '../components/Input/OInput.vue'
import OtabsKanban from 'components/Tabs/OtabsKanban.vue'
import useKanban from '../composables/useKanban'

const router = useRouter()
const route = useRoute()

const tabs = ref(router?.currentRoute?.value?.query.tab ?? 'board')
const {patchProfile, isLoading, getProfileUserId} = profileService()
const { ordenateKey, setHeightInCol } = GLOBAL
const baseSubHeaderRef = ref('')
const query = computed(
  () => `${baseSubHeaderRef.value?.filterRef?.query}` || ''
)
const searchModel = ref('')
const { urls, data, parceiro } = inject('context')
const { cols, getCols, getCards, cards, especialCols } = useKanban(urls)
const options = ref({
  produtos: [
    {
      label: 'Pessoa Física',
      disable: true,
    },
    ...data?.produtos_pf,
    {
      label: 'Pessoa Jurídica',
      disable: true,
    },
    ...data?.produtos_pj,
  ],
})
const loading = ref({
  cards: false,
})

const listaCards = ref([])
const { modalKanban, modalKanbanSide, modalKanbanIndicacaoCreate } =
  inject('modal')

function onOpenModal(item) {
  modalKanban.setState({ id: item.id, id_seguro_hub: item.id_seguro_hub })
  modalKanban.open()
}

function onClickMenu(card, type) {
  type === 'open' && modalKanban.open({ id: card.id, id_seguro_hub:card.id_seguro_hub })
}
const reduceBoolean = ref(true)
watch(
  tabs,
  async () => {
    tabs.value === 'board' && setTimeout(() => setHeightInCol(), 300)
    if (tabs.value === 'list') {
      reduceBoolean.value = false
    } else {
      reduceBoolean.value = true
    }
    router.push({ name: 'kanban', query: { tab: tabs.value, tab_page:route.query?.tab_page } })
    cols.value = []
    listaCards.value = []
    await getCols('mesa_seguro', reduceBoolean.value)
  },
  { deep: true }
)
// watch(listaCards, () => setTimeout(() => setHeightInCol(), 300))

watch(
  () => cols.value,
  () => {
    cols.value?.map(async (list) => {
      await getCards(list.id, 'mesa_seguro', query.value)

      listaCards.value.push({ ...list, cards: cards.value })
    })
  },
  { deep: true }
)

const bgFile = ref(null)

function onBgChange() {
  Dialog.create({
    component: ModalAnexo,
    componentProps: {
      title: 'Enviar Imagem',
      file: bgFile.value,
      persistent: false,
      fileProps: { accept: '.jpg, .png, .webp, .jpeg' },
    },
  }).onOk(async (file) => {
    const formData = new FormData()
    formData.append('background_kanban', file)
      const responsePatchProfile = await patchProfile(data.profile_id, formData)
      profile.value = responsePatchProfile

  })
}

function onBgRemove() {
  Dialog.create({
    component: ModalConfirm,
    componentProps: {
      title: 'Remover',
      text: 'Tem certeza que deseja remover o plano de fundo?',
      persistent: false,
    },
  }).onOk(async () => {
    const responsePatchProfile = await patchProfile(data.profile_id, { background_kanban: null, })
    profile.value = responsePatchProfile

  })
}

onMounted(async () => {
  cols.value = []
  listaCards.value = []

 // router.push({ name: 'kanban', query: { tab: tabs.value } })
  if (tabs.value === 'list') {
    reduceBoolean.value = false
  } else {
    reduceBoolean.value = true
  }
  profile.value = await getProfileUserId(data.id_user)
})

watch(
  () => query.value,
  async () => {
    cols.value = []
    listaCards.value = []
    await getCols('mesa_seguro', reduceBoolean.value)
  }
)
watch(() => [listaCards.value], () => setTimeout(() => setHeightInCol(), 300), {deep:true})
const wrapper = ref('')
const setColorsParceiroId = (parceiro) => {
  const rgbCor = GLOBAL.returnRGB(parceiro.cor1)
  const rgbCor2 = GLOBAL.returnRGB(parceiro.cor2)
  document.documentElement.style.setProperty('--primary-pure', rgbCor);
  document.documentElement.style.setProperty('--branding-pure', rgbCor);
  document.documentElement.style.setProperty('--branding-light', rgbCor);
  document.documentElement.style.setProperty('--secondary', rgbCor2);
 
}
onBeforeMount(async () => {
  setColorsParceiroId(parceiro)
})


const setBackgroundKanban = (opacity) => {
  const kanbanColWrapper = document.querySelector('.page-container')
  
  if (kanbanColWrapper) {
    const { r, g, b } = colors.hexToRgb(parceiro.cor1)
    kanbanColWrapper.style.background = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  }
onMounted(() => {
 setBackgroundKanban(0.2)
})

Emitter.on('update:kanban', async () => {
  cols.value = []
  listaCards.value = []
  await getCols('mesa_seguro', reduceBoolean.value)
})
provide('especialCols', especialCols)
provide('listaCards', listaCards)
provide('cols', cols)
provide('loading', loading)
provide('tabs', tabs)
</script>

<style lang="sass">
.page-container
  --second-top-menu: 3.5rem
  --header-bg: white
  --top-size:  var(--header-base-height)
  margin-left: var(--Nv0-sidebar-width)
  margin-top: var(--header-base-height)
  height: calc(100vh - 64px) !important
  flex-direction: column
  display: flex
  overflow-y: hidden
.kanban-container
  width: 100%
  height: calc(100% - var(--second-top-menu) - 1.7rem)
  user-select: none

.kanban-col--wrapper
  padding-top: 16px
  padding-bottom: 16px
  height: 100% !important
  display: flex
  gap: 1rem
  width: 100%
  overflow-x: auto
  flex-wrap: nowrap

  &::-webkit-scrollbar
    width: 10px

  &::-webkit-scrollbar-track
    background: rgba(var(--primary-pure), 0.05)
    border-radius: 3px

  &::-webkit-scrollbar-thumb
    background: rgba(var(--neutral-100),0.15)
    border-radius: 3px

  &::-webkit-scrollbar-thumb:hover
    background: rgba(var(--neutral-100),0.2)
</style>
